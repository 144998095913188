import secureLocalStorage from "react-secure-storage";
import { useEffect, useState } from "react";
import { config }       from "../src";
import { Texts_ita, Texts_eng } from "../texts";

// functional
import { TC_clean_storage, TC_fetch, TC_switching_store } from "../actions/TC_fetch";
import Spoiler_settings from "../components/spoilers/Spoiler_settings";
import Card_Timecapsule from "../components/cards/Card_Timecapsule";
import Card_add         from "../components/cards/Card_add";
import Dash_nav_comp    from "../components/dash/Dash_nav_comp";
import Dash_header      from "../components/dash/Dash_header";
import State_Notify     from "../components/props/State_Notify";
import useAuth          from "../hooks/useAuth";
import Connect_view     from "./Connect_view";
import Edit_view        from "./Edit_view";
import useLang               from "../hooks/useLang";
import useUserdata           from "../hooks/useUserdata";
import { scroll_to_element } from "../Utils";




export default function Dash_view () {

  // check auth
  const logged = useAuth();
  const user_data = useUserdata();
  const lang = useLang( config.lang );

  // modal views switches
  const [ addViewActive, setAddViewActive ] = useState( false );           // turns on and off the timecapsule add view
  const [ editViewActive, setEditViewActive ] = useState( false );         // turns on and off the timecapsule edit view
  const [ activateViewActive, setActivateViewActive ] = useState( false ); // turns on and off the timecapsule activate view
  const [ settingsViewActive, setSettingsViewActive ] = useState( false ); // turns on and off the navigation view

  // timecapsule states
  const [ currentTimecapsule, setCurrentTimecapsule ] = useState( false ); // when modifiyng
  const [ timecapsules, setTimecapsules ] = useState( [] );                // timecapsule list

  // UI feedback states
  const [ notify, setNotify ] = useState( { type:'pending', msg:'caricamento...' } );
  const [ update, setUpdate ] = useState( false );

  // load timecapsules
  useEffect( () => {

    TC_clean_storage();
  
    TC_fetch(
      { op : "timecapsule_list", user_id : user_data.id }
      ).then( re => {
        if ( re.status === "ok" ) {

          setTimecapsules( generate_timecapsule_list( re.timecapsule ) );
          setNotify( null );

        } else {
          setNotify({ type:'error', msg:'API fail', err:re.response });
        }
      }).catch( err => {
        setNotify({ type:'error', msg:'fetch error', err:err });
      });

  }, [ update ] );




  // TEMPLATE ...............................................
  return (
    <>
    { logged ? <div className="main-view-container view-main">

      < Spoiler_settings 
        show = { settingsViewActive }
        _toggle = { () => switch_view( 'settings' ) }
        />

      <div className="center-container">

        < Dash_header title={ lang.dash.head_title } />

        <div id="dash" className="cards-container">
          <div id="top">

            < Card_add
              text    = { lang.dash.add_tc }
              _action = { () => switch_view( 'add' ) }
              />

            < State_Notify notify={ notify } />

          </div>

          { timecapsules.map( ( tc ) => (
            < Card_Timecapsule
              token   = { { tc: tc.token } }
              _action = { () => switch_view( 'activate', tc.token ) }
              _edit   = { ( _context ) => switch_view( 'edit', _context ) }
              _delete = { () => console.log('delete') }
              update  = { update }
              key     = { tc.token }
              />
            )) }

          <div className="tc-card-bottom-filler"></div>

        </div>

        < Connect_view
          type   = "associate"
          title  = { lang.dash.connect_tc }
          scope  = "timecapsule"
          action = { () => switch_view( 'add' ) }
          show   = { addViewActive }
        />

        { currentTimecapsule &&
        <>
          < Connect_view
            type    = "activate"
            title   = { lang.dash.activate_tc }
            scope   = "timecapsule"
            action  = { () => switch_view( 'activate', currentTimecapsule ) }
            show    = { activateViewActive }
            tcToken = { currentTimecapsule }
          />

          < Edit_view
            type    = "timecapsule"
            title   = { lang.dash.modify_tc }
            _action = { () => switch_view( 'edit', currentTimecapsule ) }
            _edit   = { ( p ) => _edit( p ) }
            show    = { editViewActive }
            context = { currentTimecapsule }
          />
        </>
        }

        <div className="cont-bottom">

          < Dash_nav_comp
            backLink="/" 
            type = "login"
            _action = { () => { scroll_to_element( 'top' ) } }
            _settings = { () => switch_view( 'settings' ) }
            />

        </div>

      </div>
    </div>
    : <div>Devi passare prima dal via.</div> }
    </>
  );



  // main ..................................................
  function _edit ( p )
  {
    // TC_switching_store ( operation, data, fingerprint, timecapsule, canStoreLocally = true )    
    TC_switching_store(
      { title:p.title, description:p.description, thumb:p.thumb },
      { op:"timecapsule_set_data", tcToken:p.context.tc },
      `tcData_${p.context.tc}`,
      p.context.tc,
      config.store_basedata
    ).then( re => {

      triggerUpdate();
      switch_view( 'edit', null );
      return [ 1 , 'modified', re ];

    }).catch( err => {
      return [ 0, 'switching store error', err ];
    })
  }

  // view visibility switching .............................
  function switch_view ( view, _context ) {
    switch ( view ) {
      default:
      case 'add':
        addViewActive ? setAddViewActive( false ) : setAddViewActive( true ) ;
        if ( addViewActive ) {
          triggerUpdate();
        }
        break;
      case 'edit':
        setCurrentTimecapsule( _context );
        editViewActive ? setEditViewActive( false ) : setEditViewActive( true );
        if ( editViewActive ) {
          triggerUpdate();
        }
        break;
      case 'activate':
        setCurrentTimecapsule( _context );
        activateViewActive ? setActivateViewActive( false ) : setActivateViewActive( true );
        if ( activateViewActive ) {
          triggerUpdate();
        }
        break;
      case 'settings':
        settingsViewActive ? setSettingsViewActive( false ) : setSettingsViewActive( true );
        break;
    }
  }
  
  // helpers functions .....................................
  function generate_timecapsule_list ( list ) {
    // populate timecapsule array
    let listArray = [];

    list.map((tc) => {
      if ( tc === 'x'){
        // pass...
      } else {
        listArray.push({
          link: `/timecapsule/${tc}`,
          token: tc
        });
      }
    });

    return listArray;
  }

  function triggerUpdate()
  {
    update ? setUpdate( false ) : setUpdate( true );
  }
}