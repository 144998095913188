import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { app_src, config }    from "../src";

// functional
import { TC_fetch }  from "../actions/TC_fetch";
import friendlyToken from "../components/Utils";

// images and icons
import icon_smile from "../img/icon_smile.png"
import main_logo  from "../img/main_logo.webp";
import icon_sad   from "../img/icon_sad.png"

function Coming_soon_view () {
  // main states - params
  const { tokens } = useParams();
  const [ hasToken, setToken ] = useState( null );

  // UI feedback states
  const [ error, setError ] = useState( null );

  const navigate = useNavigate();

  useEffect( () => {
    if ( tokens != null ) {
      if ( app_src.state != 'coming_soon' ){
        navigate( `/login/${tokens}` );
      }

      TC_fetch(
        { op:"timecapsule_precheck", tcToken:tokens, }

      ).then( re => {
        if ( re.status === 'ok' ) {
          setToken( tokens );
        }
      });

    } else {
      navigate("/");
    }
  }, [ ] );

  return (
    <div className="splash-view-container view-splash">
      <div className="center-container">

        <div className="cont-in">
          <img className="tc-full-logo mb-4" src={main_logo} />
          { hasToken ?
            <>
              <h2> Timecapsule riconosciuto </h2>
              <h3 className="text-success"> <b>{friendlyToken( tokens )}</b> </h3>
              <img className="my-4" src={ icon_smile } width="50px" />
              <h5>
                Hai tutte le carte in regola per accedere a 
                <b> TIME CAPSULE </b>
              </h5>

              < Link to={ "/register/"+tokens } >
                <h5> Completa la registrazione </h5>
              </Link>

              <h5>
                <p>
                  Oppure se ti sei già registrato, presto l'applicazione
                  sarà completa e potrai memorizzare i tuoi 
                  migliori ricordi al sicuro dentro di essa
                </p>

                <p>
                  Verrai notificato via mail, appena l'applicazione verrà lanciata
                </p>
              </h5>
            </>
            :
            <>
              <h2 className="text-danger">
                <b>
                  Timecapsule <br></br>
                  non riconosciuto
                </b>
              </h2>

              <img className="my-4" src={ icon_sad } width="50px" />
              <h5> Compra un fantastico bracciale</h5>
              <h3><b>TIME CAPSULE</b></h3>
              <h5>per poter accedere all'app</h5>
            </>
          }

        { error && <div className="badre-danger">{ error }</div> }

        </div>
      </div>
    </div>
  );
}

export default Coming_soon_view