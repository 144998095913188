import { TC_Storage, TC_Storage_Secret } from "./TC_Storage";
import TC_decrypt from "./TC_decrypt";
import TC_encrypt from "./TC_encrypt";
import { config } from "../src";
import secureLocalStorage from "react-secure-storage";

export async function TC_fetch ( request, log_all = false )
{
  const _headers = { "Content-Type":"application/json" };
    return fetch( config.api_root, {
      method: 'POST',
      headers:  _headers,
      body: JSON.stringify( request )

    }).then(res => {
      if (log_all){ console.log( request ); }
      return res.json(); // pass result as json to second step

    }).then(data => {
      if (log_all){ console.log( data ); }
      return data;       // return data object.
    })

    .catch(err => {
      if (log_all){ console.log( err ); }
      return err;        // return error
    });
}

export function TC_precheck ( operation )
{
  return new Promise(( resolve, reject ) => {
    TC_fetch(
      operation
      ).then( re => {
      if ( re.status === "ok" ) {
        resolve( re );
      } else {
        reject({ msg:`API fail: ${re.response}.` });
      }
    }).catch( err => {
      reject({ msg:'Fetch fail', err:err });
    });
  });
}

export function TC_switching_fetch ( operation, fingerprint, timecapsule, canStoreLocally = true )
{
  return new Promise(( resolve, reject ) => {

    TC_Storage( 'get', fingerprint ).then( stored => {
      // fetch from local ....................................
      if ( stored != false ) {
        TC_decrypt( stored, TC_Storage_Secret( 'get', timecapsule ) ).then( decrypted => {
          resolve( JSON.parse( decrypted ) );
        }).catch( err => {
          reject({ msg:'local storage decrypt error', err:err });
        });
        
        // get remote data .....................................
      } else {
        
        TC_fetch(
          operation
        ).then( remote => {
          // check response status
          if ( remote.status === "ok" ) {
            // try store data locally if can store locally
            if ( canStoreLocally ) {
              TC_Storage( 'set', fingerprint, remote.data ).then( re => {
                if ( re == false ) {
                  console.log(`can\'t store ${fingerprint} data locally`);
                }
              }).catch( err => {
                console.log( 'local storage error: ', err );
              });
            }
  
            TC_decrypt( remote.data, TC_Storage_Secret( 'get', timecapsule ) ).then( decrypted => {
              resolve( JSON.parse( decrypted ) );
            }).catch( err => {
              reject({ msg:'server fetch decrypt error', err:err });
            });
          } else {
            reject({ msg:`API fail: ${remote.response}.` });
          }
          
        }).catch( err => {
          reject({ msg:'server fetch error', err:err });
        });
      }
    }).catch( err => {
      reject({ msg:'local storage fetch error', err:err });
    });
  });
}

export function TC_switching_store (  data, operation, fingerprint, timecapsule, canStoreLocally = true )
{
  return new Promise(( resolve, reject ) => {

    // encrypt data packet
    TC_encrypt(
      JSON.stringify( data ),
      TC_Storage_Secret( 'get', timecapsule )
    ).then( enc_data => {

      // store locally if can store locally
      if ( canStoreLocally ) {
        TC_Storage( 'set', fingerprint, enc_data ).then( re => {
          if ( re == false ) {
            console.log( 'can\'t store data locally');
          }
        })
      }

      // add data string to operation obj
      operation.data = enc_data;

      let current_storage_size = secureLocalStorage.getItem( 'tcSize'+timecapsule );
      if ( current_storage_size == null ) {
        reject([ 0, 'cant get storage size']);
      }

      if (( current_storage_size + (enc_data.length) ) >= 50000000 ) {
        reject([ 0, 'no space' ])
        
      } else {
        // send data to server
        TC_fetch(
          operation, true
        ).then( re => {
          switch ( re.status ) {
            case 'ok':
              resolve( re );
              break;

            case 'ko':
              reject({ msg:'API fail, can\'t store data', err:re.response  });
              break;
          }
        }).catch( err => {
          reject({ msg:'fetch error, can\'t store data', err:err  });
        });
      }
  
    }).catch( err => {
      reject({ msg:'server store can\'t encrypt data', err:err  });
    });
  });
}

export function TC_switching_remove ( operation, fingerprint, isStoredLocally = true )
{
  return new Promise(( resolve, reject ) => {
    TC_fetch(
      operation
      ).then( re => {
      if ( re.status === "ok" ) {

        // remove data from local storage
        TC_Storage( 'delete', fingerprint ).then( re => {
          if ( re !== true ) {
            console.log(`can\'t delete ${fingerprint} from local storage.`);
          }
          resolve( re );
        });

      } else {
        reject({ msg:`API fail: ${re.response}.` });
      }
    }).catch( err => {
      reject({ msg:'Fetch fail', err:err });
    });
  });
}

// clear storage exept for userData.
export function TC_clean_storage () {
  return new Promise(( resolve, reject ) => {
    let elements = Object.keys(localStorage);
    let keep = [ 'user', 'rc', 'tc' ];

    if ( elements ) {
      elements.forEach( element => {
        if ( !element.match(keep[0]) && !element.match(keep[1]) && !element.match(keep[2]) ) {
          secureLocalStorage.removeItem( element.substring( 10 ) );
        }
      });
    }
    resolve( true );

  });
}

export function alter_tc_storage ( tcToken, change )
{
  return new Promise(( resolve, reject ) => {
    TC_fetch(
      {
        op      : "timecapsule_change_storage",
        tcToken : tcToken,
        change  : change
      }
    ).then( re => {
      if ( re.status === "ok" ) {
        resolve ( re.storage_state );
      }
    // Handle errors .................................. ERRORS
    }).catch(err => {
      reject ( err );
    });
  });
}