function TCInput ({ name, type, value, setValue, setAfter, enabled, alert }) {
  return (
    <>
      <input 
        className={ enabled ? "tc-form-field tc-form-input show" : "tc-form-field tc-form-input hidden" } 
        name={ name } type={ type } placeholder={ name } value={ value }

        onChange={ (e) => setValue( e.target.value ) }
        onBlur={ (e) => setAfter(e) }
        />
      { alert.state && <div className={ "text-light badge-"+alert.state }> { alert.msg } </div>}
    </>
  );
}

export default TCInput;