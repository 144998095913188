import { useParams, Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { app_src }  from "../src";

// functional
import { TC_fetch } from "../actions/TC_fetch";
import QrScan_comp  from "../components/QrScan_comp";
import State_Notify from "../components/props/State_Notify";

// images and icons
import main_logo    from "../img/main_logo.webp";




function Splash_view ( ) {

  // setup hooks
  const { tokens } = useParams();
  const navigate = useNavigate();
  // setup states
  const [ hasToken, setHasToken ] = useState( false );
  const [ notify, setNotify ] = useState( { type:'pending', msg:'caricamento...' } );
  // setup tokens object
  const _tokens = { tc:tokens };




  useEffect( () => {
    if ( _tokens.tc == null ) {
      setNotify({ type:'ok' }); // wipe errors
      // has token proceed with scan ..........
    } else {
      handleToken( _tokens.tc );
    }
  }, [ ] );




  return(
    <div className="splash-view-container bg-main">
    <div className="bg-overlay-caps splash-bg-fix"></div>
      
      <div className="center-container">

        <div className="cont-in">
          <img alt="logo" className="tc-full-logo mb-4" src={main_logo} />

          { hasToken ?
            <>
              <h4>Timecapsule riconosciuto</h4>
              <Link to={ "/login" }> sono gia registrato </Link>
              <Link to={ "/register/"+_tokens.tc }> nuova registrazione </Link>
            </>
          :
            <QrScan_comp
              type="splash"
              _scanned={ (t) => handleToken( t ) }
              />
          }

          < State_Notify notify={ notify } />

        </div>
      </div>
    </div>
  );




  function handleToken ( token )
  {
    // if no token stop fetch ....................
    if ( token != null ) {
      TC_fetch(
        { op: "timecapsule_precheck", tcToken:token }

        ).then(re => {
          // check response
          if ( re.status === "ok" ) {
            if ( re.timecapsule.published === 1 ) {
              setHasToken( true );
              setNotify({ type:'ok' });

              // redirect user by app state context.
              switch ( app_src.state ) {

                case 'normal':
                  navigate( `/login/${token}` );
                  break;

                case 'coming_soon':
                  navigate( `/coming_soon/${token}` );
                  break;

                case 'maintenance':
                  navigate( `/maintenance/${token}` );
                  break;

              }
            }
          } else {
            setNotify({ type:'error', msg:'Errore precheck timecapsule' });
          }
        }).catch( err => {
          setNotify({ type:'error', msg:'Errore api', err:err });
        });
    }
  }
}

export default Splash_view;
