// images and icons
import icon_plus from "../../img/icon_plus.png";
import bg_caps from "../../img/bg_caps.png";

function Card_add ({ text, _action }) {
  return (
    <div className="tc-card tc-card-new">
      <div className="bg-overlay-caps"></div>

      <div className="tc-title">{ text }</div>
      <div className="tc-icon" onClick={ _action }><img src={ icon_plus } alt="icona più" /></div>
    </div>
  );
}

export default Card_add;
