import { useState } from "react";

// functional
import Spoiler_add from "../spoilers/Spoiler_add.jsx";

// images and icons
import icon_plus from "../../img/icon_plus.png";

function Card_add_option ({ title, _fu1, _fu2, _fu3, _fu4 }) {

  const [ spoilerState, setSpoilerState ] = useState( false );

  return (
    <div className="tc-card ">

      <div className="tc-card-test bg-main text-light" onClick={ () => switch_spoiler() }>
      <div className="bg-overlay-memory"></div>

        <div className="tc-title">{ title }</div>

        <div className="tc-icon">
          <img src={ icon_plus } alt="icona più" />
        </div>

      </div>

      <div className={ spoilerState ? "tc-card-share h-slide-in" : "tc-card-share" }>
        < Spoiler_add
          _fu1={ (e) => _fu1(e) }
          _fu2={ (e) => _fu2(e) }
          _fu3={ (e) => _fu3(e) } 
          _fu4={ (e) => _fu4(e) }
          />
      </div>
      
    </div>
  );

  function switch_spoiler () {
    spoilerState ? setSpoilerState(false) : setSpoilerState(true);
  }

}

export default Card_add_option;
