import secureLocalStorage from "react-secure-storage";
import { TC_fetch } from "./TC_fetch";

export function TC_Store ( op, key, data ) {
  return new Promise(( resolve, reject ) => {
    switch ( op ) {

      case 'get':
        let item = secureLocalStorage.getItem( key );
        if ( item != null ) {
          resolve( item );
        }
        resolve( false );
        break;

      case 'set':
        if ( key != null && key != '' && data != null && data != '' ) {
          secureLocalStorage.setItem( key, data );
          resolve( true );
        }
        resolve( false );
        break;

      case 'delete':
        if ( key != null && key != '' ) {
          secureLocalStorage.removeItem( key );
          resolve( true );
        }
        resolve( false );
        break;

    }
    reject( 'no op' );
  });
}

export function TC_Store_Secret ( op, tc, code ) {
  return new Promise(( resolve, reject ) => {
    let prefix = 'rc';
    switch ( op ) {

      case 'get':
        let item = secureLocalStorage.getItem( prefix + tc );
        if ( item != null ) {
          resolve( item );
        }
        resolve( false );
        break;
        
      case 'set':
        if ( code != null || code !== '') {
          secureLocalStorage.setItem( prefix + tc, code );
          resolve( true );
        }
        resolve( false );
        break;
    }
    reject( 'no op' );
  });
}

export function TC_Storage ( op, key, data ) {
  return new Promise(( resolve, reject ) => {
    switch ( op ) {

      case 'get':
        let item = secureLocalStorage.getItem( key );
        if ( item != null ) {
          resolve( item );
        }
        resolve( false );
        break;

      case 'set':
        if ( key != null && key != '' && data != null && data != '' ) {
          secureLocalStorage.setItem( key, data );
          resolve( true );
        }
        resolve( false );
        break;

      case 'delete':
        if ( key != null && key != '' ) {
          secureLocalStorage.removeItem( key );
          resolve( true );
        }
        resolve( false );
        break;

    }
    reject( 'no op' );
  });
}

export function TC_Storage_Secret ( op, tc, code ) {
    let prefix = 'rc';
    switch ( op ) {

      case 'get':
        let item = secureLocalStorage.getItem( prefix + tc );
        if ( item != null ) {
          return item ;
        }
        break;
        
      case 'set':
        if ( code != null || code !== '') {
          secureLocalStorage.setItem( prefix + tc, code );
          return true ;
        }
        break;
    }
    return false;
}

export function TC_size ( timecapsule ) {
  return new Promise(( resolve, reject ) => {

    TC_fetch(
      { op:"timecapsule_size", tcToken:timecapsule }
    )
    .then( re => {
      // set storage data
      if ( re.status === "ok" ) {
        
        if ( re.storage_state >= 50000000 ) {
          reject({  type:'error', msg:'timecapsule full' })
        } else {
          resolve( re.storage_state );
        }

      } else {
        reject({ msg:'failed to get size', err:re.response });
      }
    }).catch( err => { // cant list diaries
      reject({ msg:'recalc storage error', err:err });
    });
  });

}