import secureLocalStorage from "react-secure-storage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../src";

// functional
import { TC_fetch, TC_precheck, TC_switching_fetch, TC_switching_remove, TC_switching_store } from "../actions/TC_fetch";
import TC_Fetch_Multipart from "../actions/TC_Fetch_Multipart";
import TC_encrypt         from "../actions/TC_encrypt";
import Spoiler_settings   from "../components/spoilers/Spoiler_settings";
import Card_add_option    from "../components/cards/Card_add_option";
import Card_memory        from "../components/cards/Card_memory";
import Card_Empty         from "../components/cards/Card_Empty";
import State_Notify       from "../components/props/State_Notify";
import Dash_nav_comp      from "../components/dash/Dash_nav_comp";
import Dash_header        from "../components/dash/Dash_header";
import useAuth            from "../hooks/useAuth";
import Create_view        from "./Create_view";
import Edit_view          from "./Edit_view";
import { scroll_to_element } from "../Utils";
import Bubble_Message from "../components/bubbles/Bubble_Message";




function Diary_view () {
  // preliminary operations
  const logged = useAuth();
  const { tokens } = useParams();

  // view descriptive text states
  const [ date, setDate ] = useState( "" );
  const [ title, setTitle ] = useState( "" );
  const [ description, setDescription ] = useState( "" );
  const [ thumb, setThumb ] = useState( null );

  // UI feedback states
  const [ notify, setNotify ] = useState( null );
  const [ update, setUpdate ] = useState( false );
  
  // view functionality states
  const [ memories, setMemories ] = useState( [] );
  const [ dataType, setDataType ] = useState( null );
  const [ currentMemory, setCurrentMemory ] = useState( null );

  // accessory views visibility
  const [ addViewActive, setAddViewActive ] = useState( false );
  const [ editViewActive, setEditViewActive ] = useState( false );
  const [ settingsViewActive, setSettingsViewActive ] = useState( false );

  let tSplit = tokens.split(':');
  const _tokens = { tc:tSplit[0], dy:tSplit[1] };




  // get all timecapsule memories
  useEffect( () => {
    setNotify({ type:'pending', msg:'caricamento...' });

    // check token count
    if ( tSplit.length > 3 ) {
      setNotify({ type:'error', msg:'token count error' });
    }

    // get diary public data .......................
    TC_precheck(
      { op : "diary_precheck", dyToken : _tokens.dy }
    ).then( diary => {
      // set data on ui
      setDate( diary.diary.created );

    }).catch( err => {
      setNotify({ type:'error', msg:'precheck error', err:err });
    })

    // get diary private data ..............................
    refreshDiaryData ().then( diaryData => {
      // set data on ui
      setTitle( diaryData.title );
      setDescription( diaryData.description );

    }).catch( err => {
      setNotify({ type:'error', msg:'precheck error', err:err });
    });

    // get diary memories ..................................
    refreshMemoryList().then( memoryList => {
      // set data on ui
      setMemories( memoryList );
      setNotify( null );

    }).catch( err => {
      setNotify({ type:'error', msg:'precheck error', err:err });
    });
      
    return () => {
      setDate( "" );
      setTitle( "" );
      setDescription( "" );
      setThumb( null );
      setNotify( null );
      setMemories( [] );
      setDataType( null );
      setCurrentMemory( null );    
    }

  }, [ update ] );




  // TEMPLATE ------------------------------------------
  return (
    <>
    { logged == true &&
    <div className="main-view-container view-main">

      < Spoiler_settings 
        show = { settingsViewActive }
        _toggle = { () => switch_view( 'settings' ) }
        />

      <div className="center-container">

        <Dash_header title={ title } />

        <div className="cards-container">
          
          <div id="top" >

            < Card_add_option
              title    = { <div>Crea <br/> Memoria</div> }
              _fu1     = { ( e ) => switch_view( 'add', e ) }
              _fu2     = { ( e ) => switch_view( 'add', e ) }
              _fu3     = { ( e ) => switch_view( 'add', e ) }
              _fu4     = { ( e ) => switch_view( 'add', e ) }
              />

            < Bubble_Message style='solo' message={ description } />
            < State_Notify notify={ notify } />
            
          </div>

          { memories.map( ( mem ) => (
            < Card_memory 
              tokens   = { { tc:_tokens.tc, dy:_tokens.dy, mem:mem.token } }
              _edit    = { ( e ) => switch_view( 'edit', e ) }
              _delete  = { ( e ) => _delete( e ) }
              update   = { update }
              key      = { mem.token }
              />
            ) ) }

          { memories.length === 0 &&
            < Card_Empty scope="memorie" />
          }

          <div className="tc-card-bottom-filler"></div>

        </div>

        <div className="cont-bottom">
          < Dash_nav_comp
            backLink={ `/timecapsule/${_tokens.tc}` }
            type = "timecapsule"
            _action={ () => scroll_to_element( 'top' ) }
            _settings = { () => switch_view( 'settings' ) }
            />
        </div>

        { dataType && 
        < Create_view
          type     = "memoria"
          title    = "Crea Memoria"
          _action  = { () => switch_view( 'add' ) }
          _create  = { ( p ) => _create( p ) }
          show     = { addViewActive }
          context  = {{ tc:_tokens.tc, dy:_tokens.dy, dataType:dataType }}
          />}

        { currentMemory &&
        < Edit_view
          type     = "memoria"
          title    = "modifica memoria"
          _action  = { () => switch_view( 'edit', currentMemory ) }
          _edit    = { ( p ) => _edit( p ) }
          show     = { editViewActive }
          context  = { currentMemory }
          />
        }

      </div>
    </div>
    }
    </>
  );




  /**  handles memory creation
   * p parameter contains an object with data for memory creation
   * p fields will be checked here for content existence.
   * 
   * p.memory contents will be pre checked before reaching this point
   * size checks and limitations will be performed before upload.
   */
  function _create ( p )
  {
    return new Promise(( resolve, reject ) => {
      if ( !p ) { reject( 'no param' ); }
      // init data containers.
      let _data, _memory, _thumb = {};
      
      // IMAGE OR VIDEO DATATYPE ...................................
      // CHECK DATA AND PROCEED ...................................
      if ( p.context.dataType === 'img' || p.context.dataType === 'vid' ) {
        if ( p.title === '' || p.description === '' || !p.memory || !p.thumb ) {
          resolve ([ 0, 'compila tutti i campi']);
        } else {
          // create memory data object
          _data   = { title:p.title, description:p.description };
          _memory = { memory:p.memory };
          _thumb  = { thumb:p.thumb };

          // try encrypt memory content object .................... ENCRYPT
          TC_encrypt( JSON.stringify( _memory ), secureLocalStorage.getItem( 'rc'+p.context.tc ) ).then( enc_memory => {
            TC_encrypt( JSON.stringify( _thumb ), secureLocalStorage.getItem( 'rc'+p.context.tc ) ).then( enc_thumb => {
              TC_encrypt( JSON.stringify( _data ), secureLocalStorage.getItem( 'rc'+p.context.tc ) ).then( enc_data => {

                // size check before uploading .................... CHECK
                let current_storage_size = secureLocalStorage.getItem( 'tcSize'+p.context.tc );

                if ( current_storage_size == null ) {
                  resolve([ 0, 'cant get storage size']);
                }

                if ( current_storage_size + ( (enc_memory.length) + (enc_thumb.length) + (enc_data.length) ) >= 50000000 ) {
                  resolve([ 0, 'no space' ])

                } else {
                  // send data to endpoint.. must be form multi part...FETCH memory add
                  TC_Fetch_Multipart(
                    config.api_root_mult,
                    {
                      op         : "memory_add_thumb",
                      tcToken    : p.context.tc,
                      dyToken    : p.context.dy,
                      memData    : enc_data,
                      memThumb   : enc_thumb,
                      memContent : enc_memory,
                      dataType   : p.context.dataType

                    }).then(  re => {
                    // set storage data
                    if (  re.status === "ok" ) {
                      // close add view
                      switch_view( 'add' );
                      triggerUpdate();
                      resolve ([ 1 ]);
                    } else {
                      resolve ([ 0, 'no op' ]);
                    }
                  // Handle errors ............................... ERRORS
                  }).catch( err => {
                    resolve ([ 2, 'fetch error', err ]);
                  });
                }

              }).catch( err => {
                resolve ([ 2, 'encrypt error', err ]);
              });
            }).catch( err => {
              resolve ([ 2, 'encrypt error', err ]);
            });
          }).catch( err => {
            resolve ([ 2, 'encrypt error', err ]);
          });
        }

      // AUDIO OR TEXT DATATYPE ...................................
      // CHECK DATA AND PROCEED ...................................
      } else {
        if ( p.context.dataType === 'txt' ) {
          _data   = { title:p.title };
          if ( p.title === '' || !p.memory ) {
            resolve ([ 0, 'compila tutti i campi']);
            }  
        } else {
          _data   = { title:p.title, description:p.description };
          if ( p.title === '' || p.description === '' || !p.memory ) {
            resolve ([ 0, 'compila tutti i campi']);
          }
        }

        _memory = { memory:p.memory };

        // try encrypt memory content object .................... ENCRYPT
        TC_encrypt( JSON.stringify( _memory ), secureLocalStorage.getItem( 'rc'+p.context.tc ) ).then( enc_memory => {
          TC_encrypt( JSON.stringify( _data ), secureLocalStorage.getItem( 'rc'+p.context.tc ) ).then( enc_data => {

            // size check before uploading .................... CHECK
            let current_storage_size = secureLocalStorage.getItem( 'tcSize'+p.context.tc );

            if ( current_storage_size == null ) {
              resolve([ 0, 'cant get storage size']);
            }

            if ( current_storage_size + ( (enc_memory.length) + (enc_data.length) ) >= 50000000 ) {
              resolve([ 0, 'no space' ])

            } else {
              // send data to endpoint.. must be form multi part...FETCH memory add
              TC_fetch(
                {
                  op         : "memory_add_simple",
                  tcToken    : p.context.tc,
                  dyToken    : p.context.dy,
                  memData    : enc_data,
                  memContent : enc_memory,
                  dataType   : p.context.dataType

                }).then( re => {
                // set storage data
                if ( re.status === "ok" ) {
                  // close add view
                  switch_view( 'add' );
                  triggerUpdate();
                  resolve ([ 1 ]);
                } else {
                  resolve ([ 0, 'no op' ]);
                }
              // Handle errors ............................... ERRORS
              }).catch( err => {
                resolve ([ 2, 'fetch error', err ]);
              });
            }

            }).catch( err => {
              resolve ([ 2, 'encrypt error', err ]);
            });
          }).catch( err => {
            resolve ([ 2, 'encrypt error', err ]);
          });
      }
    });
  }

  // ok
  function _edit ( p )
  {
    return new Promise(( resolve, reject ) => {
      if ( !p ) { reject( 'no param' ); }
      // check for differences before sending data to server...
      if ( p.titleUpdated || p.descriptionUpdated ) {

        store_data( p ).then( re => {
          switch_view( 'edit' );
          resolve( re );

        }).catch( err => {
          reject( err );
        });
      }

    });
  }

  // ok
  function _delete ( p )
  {
    return new Promise(( resolve, reject ) => {
      if ( !p ) { reject( 'no param' ); }

      TC_switching_remove(
        { op:"memory_remove", tcToken:p.tokens.tc, dyToken:_tokens.dy, memToken:p.tokens.mem },
        `memData_${p.tokens.dy}`
      ).then( re => {
        
        triggerUpdate ();
        resolve ([ 1, 'memory removed', re ]);

      }).catch( err => {
        resolve([ 2, 'fetch error', err ]);
      })

    });
  }

  function refreshMemoryList () {
    return new Promise(( resolve, reject ) => {
      TC_fetch(
        { op:"memory_list", dyToken:_tokens.dy }
        )
        .then(data => {
          // set storage data
          if ( data.status === "ok" ) {
  
            let mem_arr = [];
            data.memories.map((mem) => {
              if ( mem === 'x'){
                // pass...
              } else {
                mem_arr.push({
                  token: mem,
                });
              }
            });
            resolve( mem_arr );

          }
        }).catch(err => { // ............................... ERRORS
          reject({ type:'error', msg:'fetch error', err:err });
        });
    });
  }

  function refreshDiaryData () {
    return new Promise(( resolve, reject ) => {
      TC_switching_fetch (
        { op:"diary_get_data", tcToken:_tokens.tc, dyToken:_tokens.dy },
        `dyData_${_tokens.dy}`,
        _tokens.tc,
        config.store_basedata
      ).then( data => {
        resolve( data );
  
      }).catch( err => {
        reject({ type:'error', msg:err.msg, err:err.err });
      });
    });
  }

  // data will be stored always locally
  function store_data ( p ) {
    return new Promise(( resolve, reject ) => {
      TC_switching_store(
        { title:p.title, description:p.description },
        { op:"memory_update_data", tcToken:p.context.tc, dyToken:p.context.dy, memToken:p.context.mem },
        `memData_${p.context.mem}`,
        p.context.tc
      ).then( re => {
        resolve ([ 1 , re ]);
      }).catch( err =>  {
        reject ([ 0, 'Edit error', err ]);
      });
    });
  }

  function switch_view ( view, _context ) {
    switch ( view ) {
      default:
      case 'add':
        setDataType( _context );
        addViewActive ? setAddViewActive( false ) : setAddViewActive( true );
        if ( addViewActive ) {
          triggerUpdate();
        }
        break;
      case 'edit':
        setCurrentMemory( _context );
        editViewActive ? setEditViewActive( false ) : setEditViewActive( true );
        if ( editViewActive ) {
          triggerUpdate();
        }
        break;
      case 'settings':
        settingsViewActive ? setSettingsViewActive( false ) : setSettingsViewActive( true );
        break;
    }
  }

  // helpers
  function triggerUpdate ()
  {
    update ? setUpdate( false ) : setUpdate( true );
  }

}

export default Diary_view;
