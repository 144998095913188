
import Compressor from "compressorjs";

function File_Process ( file, file_type ) {
  return new Promise(( resolve, reject ) => {
    switch ( file_type ) {
      case 'img':
      case 'vid':
      case 'aud':
        readFile( file, ( res ) => {
          resolve( res );
        });
        break;
      default:
        reject('no case');
        break;
    }
  });
}

export function Image_process ( image, isCover = false )
{
  return new Promise(( resolve, reject ) => {

    if ( isCover == true ) {
      // create a new compressed image varsion
      new Compressor( image, {
        // type settings
        quality: 0.5,
        maxWidth: 600,
        maxHeight: 600,
        mimeType: 'image/webp',
        success: ( comp1 ) => {
          // read contents of new compressed image
          readFile( comp1, ( content ) => {
            // encrypt image contents with user secret
            resolve( { clear:comp1, bytes:content } );
          });
        },
        error: ( err ) => {
          reject( err );
        },
      })
    // same as above with different compression parameters
    } else {
      new Compressor( image, {
        quality: 0.9,
        mimeType: 'image/webp',
        success: ( comp2 ) => {
          readFile( comp2, ( content ) => {
            resolve( { clear: comp2, bytes:content } );
          });
        },
        error: ( err ) => {
          reject( err );
        }
      });
    }
  });
}

export function to_blob( dataString )
{
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  let byteString = atob( dataString.split(',')[1] );
  var mimeType = dataString.split(',')[0].split(':')[1].split(';')[0]
  var ab = new ArrayBuffer( byteString.length );
  // create a view into the buffer
  var ia = new Uint8Array( ab );
  // set the bytes of the buffer to the correct values
  for ( var i = 0; i < byteString.length; i++ ) {
      ia[i] = byteString.charCodeAt(i);
  }

  let newBlob = new Blob( [ ab ], { type: mimeType });
  // write the ArrayBuffer to a blob
  return [ newBlob, mimeType ];
}

async function readFile( file, cb )
{
  let reader = new FileReader();
  reader.onload = ( e ) => {
    return cb( e.target.result );
  }
  reader.readAsDataURL(file);
}

export default File_Process;
