function Date_since ({ date }) {
  const c_date = new Date();
  const c_year = c_date.getFullYear();
  const c_month = c_date.getMonth();
  const c_day = c_date.getDate();
  let nuDate = c_day+"-"+c_month+"-"+c_year;
  // eval date to create a "since when" friendly date
  return (
    <div className="card-date">{ date }</div>
  );
}

export default Date_since;