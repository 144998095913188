import "./badges.css";

export default function State_Notify ({ notify }) {

  if ( notify == null) {
    return (<></>);
  }

  let type = notify.type;
  let message = notify.msg;
  let error = notify.err;

  if ( error != null ) {
    console.log( error );
  }

  return(
    <>

      { type && type === 'error' &&
      <div className="badge-danger">
        { message && message }
      </div>
      }

      { type && type === 'warning' &&
      <div className="badge-warning">
        { message && message }
      </div>
      }

      { type && type === 'success' && 
      <div className="badge-success">
        { message && message }
      </div>
      }

      { type && type === 'pending' 
      && <div className="text-light">
        { message && message }
      </div>
      }

    </>
  )
}