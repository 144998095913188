// images and icons
import icon_fb   from "../../img/spoiler_fb_icon.png";
import icon_ig   from "../../img/spoiler_ig_icon.png";
import icon_twit from "../../img/spoiler_twitter_icon.png";

function Spoiler_share ({ fu1, fu2, fu3 }) {
  return (
    <div className="spoiler-cont">
      { fu1 && <img className="spoiler-icon" onClick={ fu1 } src={ icon_fb } alt="FB" />}
      { fu2 && <img className="spoiler-icon" onClick={ fu2 } src={ icon_ig } alt="IG" />}
      { fu3 && <img className="spoiler-icon" onClick={ fu3 } src={ icon_twit } alt="TWIT" />}
    </div>
  );
}

export default Spoiler_share;