import { useMemo } from "react";
import secureLocalStorage from "react-secure-storage";

export default function useUserdata ( ) {

  const userData = useMemo(() => {
    let dataString = secureLocalStorage.getItem('userData');
    if ( dataString ) {
      return JSON.parse( dataString );
    }
  }, []);
  
  return userData;
}