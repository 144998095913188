import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { app_src }  from "../src";

// functional
import { TC_fetch } from "../actions/TC_fetch";
import TCInput      from "../components/dash/TCInput";

// images and icons
import main_logo    from "../img/main_logo.webp";
import State_Notify from "../components/props/State_Notify";




function Register_view()
{
  // get parameters
  const navigate = useNavigate();
  const { tokens } = useParams();
  const _tokens = { tc:tokens };

  // field states
  const [ userName, setUserName ] = useState( '' );
  const [ repPass, setRepPass ] = useState( '' );
  const [ email, setEmail ] = useState( '' );
  const [ pass, setPass ] = useState( '' );

  // ui alerts states
  const noAlert = { state : null, msg : "" };
  const [ userName_alert, setUserName_alert ] = useState( noAlert );
  const [ repPass_alert, setRepPass_alert ] = useState( noAlert );
  const [ email_alert, setEmail_alert ] = useState( noAlert );
  const [ pass_alert, setPass_alert ] = useState( noAlert );

  // field states
  const [ confirmState, setConfirmState ] = useState( false );
  const [ fieldsB, setFieldsB ] = useState( false );
  const [ fieldsA, setFieldsA ] = useState( true );
  const [ step, setStep ] = useState( 1 );

  // async feedback states
  const [ notify, setNotify ] = useState( { type:'pending', msg:'caricamento...' } );




  useEffect( () => {
    if ( _tokens.tc != null ) { // check token
      // ............................................................... FETCH timecapsule-precheck
      TC_fetch(
        { op: "timecapsule_precheck", tcToken:_tokens.tc }
        ).then(re => {
          // check status
          if ( re.status === "ok" ) {
            // for activation to take place a timecapsule needs to be published and inactive
            if ( re.timecapsule.published === 1 && re.timecapsule.state === 0 ) {
              // swith on app state, in case of maintenance of others.
              switch ( app_src.state ) {

                case 'normal':
                case 'coming_soon':
                  setNotify( null );
                  break;
                  
                  case 'maintenance':
                  setNotify( null );
                  navigate( `/maintenance/${_tokens.tc}` );
                  break;
              
              }
            // ......................................................... ERROR WALL !!
            } else { // timecapsule not published or already owned !!
              setNotify({ type:'error', msg:'timecapsule already owned.' });
              navigate("/");
              }
              } else { // time capsule not found !!
            setNotify({ type:'error', msg:'Timecapsule can\'t be found.' });
            navigate("/");
            }
            }).catch( err => { // timecapsule precheck error !!
          setNotify({ type:'error', msg:'fetch error.', err:err });
          });
          } else { // null token
        setNotify({ type:'error', msg:'No token prop.' });
        navigate("/");
      }
  }, [] );




  return (
    <div className="splash-view-container bg-main">
      <div className="bg-overlay-caps splash-bg-fix"></div>
      <div className="center-container">

        <div className="cont-top cont-top-splash">
          <img alt="logo" className="tc-full-logo" src={ main_logo } />
        </div>
        
        <div className="cont-in">

          { step === 1 &&
          <>
            <form>
              < TCInput name="nome utente" type="text" value={ userName }
                  setValue = { setUserName }
                  setAfter = { precheckUser }
                  enabled  = { fieldsA }
                  alert    = { userName_alert }
                  />

              < TCInput name="email" type="email" value={ email }
                setValue = { setEmail }
                setAfter = { checkEmail }
                enabled  = { fieldsA }
                alert    = { email_alert }
                />
            </form>
            <p className="text-light mt-4">Assicurati di inserire una email corretta, 
              ti verrà inviato un OTP per confermare il tuo account. 
              inserendo una mail sbagliata perderai l'accesso 
              alla tua timecapsule che viene usata per attivare l'account.</p>
          </>
          }

          { step === 2 &&
          <form>
            < TCInput name="password" type="password" value={ pass }
                setValue = { setPass }
                setAfter = { () => {} }
                enabled  = { fieldsB }
                alert    = { pass_alert }
                />

            < TCInput name="ripeti password" type="password" value={ repPass }
              setValue = { setRepPass }
              setAfter = { checkRepPass }
              enabled  = { fieldsB }
              alert    = { repPass_alert }
              />
          </form>
          }

          { step === 3 &&
          <p>
            Complimenti hai completato la registrazione.
            Vai al login per accedere alla tua TIME CAPSULE
          </p>
          }

        </div>

        <div className="cont-bottom cont-bottom-splash">
          { step === 1 && < button className="btn-tc-big" disabled={ !confirmState } onClick={ () => changeStep(2) } > Prosegui </button> }
          { step === 2 && < button className="btn-tc-big" disabled={ !confirmState } onClick={ () => handleSubmit() } > Conferma </button> }
        </div>

        < State_Notify notify={ notify } />

      </div>
    </div>
  );


  function fields_a_ok () {
    if (
      userName_alert.state !== 'danger' && userName !== '' &&
      email_alert.state !== 'danger' && email !== ''
    ) {
      console.log( userName_alert, email_alert );
      return true;
    }
    return false;
  }

  function fields_b_ok () {
    if (
      pass_alert.state !== 'danger' && pass !== '' &&
      repPass_alert.state !== 'danger' && repPass !== ''
    ) {
      console.log( pass_alert, repPass_alert );
      return true;
    }
    return false;
  }

  function handleSubmit ()
  {
    if ( !fields_a_ok() || !fields_b_ok() ) {
      setNotify({ type:'warning', msg:'ci sono errori nei campi' });
      return;
    }
    // update async ops ui state
    setNotify({ type:'pending', msg:'invio i dati ...' });

    // build call ..................................
    TC_fetch(
      { 
        op: "user_add", tcToken:_tokens.tc,
        userName, pass, email 
      }).then(re => {

        if ( re.status === "ok" ) {
          if ( app_src.state === "coming_soon" ) {
            navigate( `/coming_soon/${_tokens.tc}` );
          }
          navigate( `/login/${_tokens.tc}` );
          setNotify( null );

        } else { // status KO !!
          setNotify({ type:'warning', msg:re.response });
        }
      })
      .catch( err => {
        
        setNotify({ type:'error', msg:'fetch error.', err:err });
      });
  }

  function checkRepPass ()
  {
    if (pass !== repPass) {
      setRepPass_alert({ state:"danger", msg:'Password diverse !'});
    } else {
      setRepPass_alert( noAlert );
    }
  }

  function precheckUser ()
  {
    if ( userName.length > 32 ) {
      setUserName_alert({ state: "danger", msg: 'Lo username deve essere lungo massimo 32 caratteri' });
      return;
    }
    // make call ................. //
    TC_fetch(
      { op: "user_precheck", 
        userName
      }).then(data => {
        if ( data.userName === "inexistent" ){
          setUserName_alert({ state: "success", msg: "disponibile" });
        } else {
          setUserName_alert({ state: "warning", msg: "non disponibile" });
        }
      })
      
      .catch(err => {
        setUserName_alert({ state: "danger", msg: err });
      });
  }

  function checkEmail ()
  {
    if ( !email.includes('@') ) {
      setEmail_alert({ state:'danger', msg:'non è una mail...'});
    } else {
      setEmail_alert({ state:'success', msg:'mail accettata'});
      if ( userName_alert.state === "success" ){
        setConfirmState(true);
      }
    }
  }

  function changeStep ( step )
  {
    switch ( step ) {

      case 2:
        if ( fields_a_ok() ) {
          setStep( 2 );
          // update field states
          setFieldsA( false );
          setFieldsB( true );
        } else {
          console.log('fields a not ok');
        }
        break;

      case 3:
        if ( fields_a_ok() && fields_b_ok() ) {
          setStep( 3 );
          // update field states
          setFieldsA( false );
          setFieldsB( false );
        }
        break;
    
      default:
        break;
    }
  }

}




export default Register_view;
