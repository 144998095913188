
export const app_src = {
  state : "normal"
}

const env = 'public';
export var config;

export var app_info = {
  vers: 'V 0.9 BETA'
};

switch ( env ) {

  case 'public':
    config = {
      lang           : 'ita',
      api_root       : 'https://ss.tcapp.it/timecapsule.php',
      api_root_mult  : 'https://ss.tcapp.it/timecapsule_mult.php',
      img_root       : 'img/',
      store_basedata : true,
      store_thumb    : true,
      store_content  : false
    };
    
  break;
    
  case 'local':
    config = {
      lang           : 'ita',
      api_root       : 'http://localhost:80/ss.timecapsule/public.ss/api/timecapsule.php',
      api_root_mult  : 'http://localhost:80/ss.timecapsule/public.ss/api/timecapsule_mult.php',
      img_root       : 'img/',
      store_basedata : true,
      store_thumb    : true,
      store_content  : false
    };
      
  break;

  default:
    break;
}

