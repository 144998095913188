// images and icons
import icon_image from "../../img/spoiler_image_icon.png";
import icon_video from "../../img/spoiler_video_icon.png";
import icon_audio from "../../img/spoiler_audio_icon.png";
import icon_text  from "../../img/spoiler_text_icon.png";

function Spoiler_add ({ _fu1, _fu2, _fu3, _fu4 }) {
  return (
    <div className="spoiler-cont">
      { _fu1 && <img className="spoiler-icon" onClick={ () => _fu1('img') } src={ icon_image } alt="immagine"/> }
      { _fu2 && <img className="spoiler-icon" onClick={ () => _fu2('vid') } src={ icon_video } alt="video" /> }
      { _fu3 && <img className="spoiler-icon" onClick={ () => _fu3('aud') } src={ icon_audio } alt="audio" /> }
      { _fu4 && <img className="spoiler-icon" onClick={ () => _fu4('txt') } src={ icon_text }  alt="testo" /> }
    </div>
  );
}

export default Spoiler_add;