import { useMemo } from "react";
import { Texts_ita, Texts_eng } from "../texts";

export default function useLang ( lang ) {
  const language = useMemo(() => {
    switch ( lang ) {

      default:
      case 'ita':
        return Texts_ita ;

      case 'eng':
        return Texts_eng ;
    }
  }, [ lang ]);

  return language;
}