import secureLocalStorage from "react-secure-storage";
import { useState } from "react";

import { TC_Store, TC_Store_Secret } from "../actions/TC_Storage";
import { Image_process } from "../actions/File_Process";
import TC_encrypt        from "../actions/TC_encrypt";
import { TC_fetch }      from "../actions/TC_fetch";
import State_Notify      from "../components/props/State_Notify";
import QrScan_comp       from "../components/QrScan_comp";

// images and icons
import icon_close from "../img/icon_close.png";
import bg_caps_dark from "../img/bg_caps_dark.png";

/**
 * 
 * @param { string } type association type - associate or activate for now
 * @param { string } title main window title 
 * @param { string } scope utility word 
 * @param { function } action confirm button function ( from parent )
 * @param { boolean } show main window show ( visible or not - fading in or out) 
 * @returns connect view html template.
 * 
 * connects a new timecapsule to subscription
 * action flow:
 * scan first qr ->
 * set title ->
 * scan security qr ->
 * approve ->
 * confirm.
 * 
 */
function Connect_view ({ type, title, scope, action, show, tcToken }) {

  const [ firstRun, setFirstRun ] = useState( true );
  const [ qrData, setQrData ] = useState( null );
  const [ qrReaded, setQrReaded ] = useState( false );
  const [ password, setPassword ] = useState( '' );
  
  // data states
  const [ thumb, setThumb ] = useState( null );
  const [ cover, setCover ] = useState( null );
  const [ tcTitle, setTcTitle ] = useState( '' );
  const [ tcDescription, setTcDescription ] = useState( '' );

  // feedback states
  const [ notify, setNotify ] = useState( null );



  /**
   * associate a new timecapsule to a subscription.
   * if all is fine go back.
  */
  function tc_associate ( )
  {
    setNotify({ type:'pending', msg:'caricamento...' });
    // DO Nothing if token is not complete..
    if ( !qrData ) { setNotify({ type:'error', msg:'Codice incompleto!' }); return 0; }

    let uData = JSON.parse(secureLocalStorage.getItem( "userData" ));

    if ( !uData ) { setNotify({ type:'error', msg:'No user!' }); return 0; }
    // ..................................................... FETCH timecapsule_associate
    TC_fetch
    (
      { op:"timecapsule_associate", userId:uData.id, tcToken:qrData }
      ).then( re => {
        if ( re.status === 'ok' ) {
          triggerFirstRun();
          setNotify( null );
          cleanup();
          return 1;

        // ERRORS ..........................................
        } else { // timecapsule associate responded ko !!
          setNotify({ type:'error', msg:'Non posso associare!' });
        }
      }).catch( err => { // timecapsule-associate fetch error !!
        setNotify({ type:'error', msg:'fetch fail!', err:err });
      });

      return 0;
  }

  /**
   * Activate an already associated timecapsule.
   */
  function tc_activate ( )
  {
    setNotify({ type:'pending', msg:'caricamento...' });

    // DO Nothing if token is not complete..
    if ( !checkFields() ){ setNotify({ type:'error', msg:'compila tutti i campi' }); return 0; }
    if ( !qrData ) { setNotify({ type:'error', msg:'nessuna chiave!' }); return 0; }
    if ( !password ) { setNotify({ type:'error', msg:'Inserisci la password!' }); return 0; }

    // get stored user data.
    let uData = JSON.parse(secureLocalStorage.getItem('userData'));
    if ( !uData ) { setNotify({ type:'error', msg:'Errore utente, vai al login!' }); return 0; }

    // Perform a log to check if data is correct ........................... FETCH user_log
    TC_fetch(
      { op:"user_log", userName:uData.user_name, userPass:password }

      ).then( log_resp => {

      switch ( log_resp.status ) {

        case "ok":
          // encrypt secret with user password ............................. ENCRYPT
          TC_encrypt( qrData, password ).then( cipherSecret => {
            // set data on local storage
            if ( !TC_Store_Secret( 'set', tcToken, qrData ) ) {
              setNotify({ type:'error', msg:'Non posso archiviare il segreto' });
              return;
            }
            // set data packet. title - description - thumb blob.
            let _data = { title: tcTitle, description: tcDescription, thumb:thumb };
            
            // encrypt data packet ......................................... ENCRYPT
            TC_encrypt( JSON.stringify( _data ), qrData ).then( cipherString => {

              // send data to server -> activate timecapsule ............... FETCH
              TC_fetch(
                {
                  op:"timecapsule_activate",
                  tcToken:tcToken,
                  tcSecret:qrData,
                  userTCSecret:cipherSecret,
                  tcData:cipherString

                }).then( re => {
                  if ( re.status === 'ok') {

                    // store data for fast retrieval .....................
                    TC_Store( 'set', `tcData_${tcToken}`, cipherString );
                    triggerFirstRun(); // switch view off.
                    setNotify( null ); // reset notifies.
                    cleanup();
                  } else {
                    setNotify({ type:'error', msg:re.response });
                  }
              // Handle errors ........................................... ERRORS
              }).catch( err => {
                setNotify({ type:'error', msg:'Errore durante l\'attivazione!', err:err });
                return;
              });
            }).catch( err => {
              setNotify({ type:'error', msg:'Errore crittografia dati!', err:err });
              return;
            });
          }).catch( err => {
            setNotify({ type:'error', msg:'Errore crittografia chiave!', err:err });
            return;
          });
          break;

        case "ko":
          setNotify({ type:'error', msg:log_resp.response });
          break;

      }
    }).catch( err => {
      setNotify({ type:'error', msg:'Utente non riconosciuto!', err:err });
      return;
    });
  }




  return(
    <div className={ show ? "cont-fill cc-fadein above" : firstRun ? "cont-fill hidden above" : "cont-fill cc-fadeout above" } >
      <div className={ show ? "card-create cc-fadein above" : firstRun ?  "card-create hidden above" : "card-create cc-fadeout above" } >
        <div className="bg-overlay-caps-dark"></div>

        <div className="cc-title-container">
          <div className="cc-title">{ title }</div>
          <div className="tc-icon" onClick={ () => action() }>
            <img src={ icon_close } alt="X" />
          </div>
        </div>

        <div className="cc-ok">
          <div className="cc-body-container">

            { type === "associate" &&
              <>
              { !qrReaded &&
                < QrScan_comp
                  type="assoc"
                  _scanned={ (t) => check_qr( t ) }
                />
              }
              </>
            }

            { type === "activate" &&
              <>

                { !qrReaded &&
                  < QrScan_comp
                    type="activate"
                    _scanned={ (t) => check_qr( t ) }
                  />
                }

                { qrReaded &&
                <>

                  <label htmlFor="title"> Nome: </label>
                  <input 
                    name="title" 
                    type="text" 
                    placeholder={ "nome " + scope }
                    onChange={ (e) => setTcTitle( e.target.value ) }
                    value={ tcTitle }
                    />

                  <label htmlFor="cover">Copertina</label>
                  <input
                    className="cc-cover rounded-min"
                    name="cover"
                    style={{
                      backgroundColor: 'rgba(59,47,157,0.5)',
                      backgroundImage: `url(${cover})`
                    }}
                    type="file"
                    onChange={ (e) => processCover( e.target.files) }
                    />

                  <label htmlFor="description"> Descrizione breve: </label>
                  <textarea
                    name="description"
                    rows="4"
                    maxLength="256"
                    placeholder={ "descrizione " + scope }
                    onChange={ (e) => setTcDescription( e.target.value ) }
                    value={ tcDescription }
                    />

                  <p className="text-light mt-4"> per questioni di sicurezza reinserisci la tua password </p>
                  
                  <input
                    name="password" 
                    type="password" 
                    placeholder={ "password" }
                    onChange={ (e) => setPassword( e.target.value ) }
                    value={ password }
                    />

                </>
                }
              </>
            }

            < State_Notify notify={ notify } />
          </div>
        </div>

        <div className="cc-foot">
          
          { type === "associate" &&
            <button onClick={ tc_associate } className="btn-tc-big btn-tc-cc-confirm">ASSOCIA</button>
          }

          { type === "activate" && qrReaded === false &&
            <button onClick={ tc_check } className="btn-tc-big btn-tc-cc-confirm">CONTROLLA</button>
          }

          { type === "activate" && qrReaded === true &&
            <button onClick={ tc_activate } className="btn-tc-big btn-tc-cc-confirm">ATTIVA</button>
          }

        </div>

      </div>
    </div>
  );




  function checkFields ( )
  {
    if ( cover == null || thumb === '' || tcTitle === '' || tcDescription === '' ) {
      return false;
    }
    return true;
  }

  // check if qr string is of correct length..
  function check_qr ( qrString )
  {
    if ( qrString.length === 16 ) {
      setQrData( qrString );
    } else {
      setQrData( null );
    }
  }
  
  function tc_check ( )
  {
    setNotify({ type:'pending', msg:'controllo timecapsule...' })
    // DO Nothing if token is not complete..
    if ( !qrData ) { 
      setNotify({ type:'error', msg:'Codice incompeto!' })
      return;
    }
    // ........................................... FETCH secret precheck
    TC_fetch(
      { op:"secret_precheck", tcToken:tcToken, tcSecret:qrData }

      ).then( re => {
        if ( re.status === 'ok') {
          setQrReaded(true);
          setNotify({ type:'success', msg:'codice corretto!' });

        } else {
          setQrReaded(false);
          setNotify({ type:'error', msg:re.response })
        }
    // Handle errors ...........................
    }).catch(err => {
      setNotify({ type:'error', msg:'fetch fail!', err:err })
    });
  }

  // returns a compressed verion of an image
  function processCover ( images )
  {
    // set cover in realtime
    setCover( URL.createObjectURL( images[0] ) );
    // process image to a lower resolution webp image
    Image_process( images[0], true ).then( thumb => {
      setThumb( thumb.bytes );
      setNotify( null );
    }).catch( err => {
      setNotify({ type:'error', msg:'Errore elaborazione immagine!', err:err });
    });

    return;
  }

  function triggerFirstRun ( )
  {
    setFirstRun(false);
    action();
  }

  function cleanup () {
    setQrData( null );
    setPassword( '' );
    setThumb( null );
    setCover( null );
    setTcTitle( '' );
    setTcDescription( '' );
    setNotify( null );
  }

}




export default Connect_view;
