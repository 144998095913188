import secureLocalStorage from "react-secure-storage";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { config }    from "../src";

// functional
import { TC_fetch, TC_switching_remove, TC_switching_fetch, TC_switching_store } from "../actions/TC_fetch";
import TC_encrypt       from "../actions/TC_encrypt";
import { TC_Store }     from "../actions/TC_Storage";
import Spoiler_settings from "../components/spoilers/Spoiler_settings";
import State_Notify     from "../components/props/State_Notify";
import Dash_nav_comp    from "../components/dash/Dash_nav_comp";
import Dash_header      from "../components/dash/Dash_header";
import Card_Diary       from "../components/cards/Card_Diary";
import Card_Empty       from "../components/cards/Card_Empty";
import Card_add         from "../components/cards/Card_add";
import useAuth          from "../hooks/useAuth";
import Create_view      from "./Create_view";
import Edit_view        from "./Edit_view";
import Bubble_Message   from "../components/bubbles/Bubble_Message";
import { scroll_to_element } from "../Utils";




export default function Timecapsule_view () {

  // preliminary operations
  const logged = useAuth();
  const { tokens } = useParams();
  const _tokens = { tc:tokens };

  // timecapsule states
  const [ title, setTitle ] = useState( "" );
  const [ description, setDescription ] = useState( "" );
  const [ thumb, setThumb ] = useState( null );
  const [ diaries, setDiaries ] = useState( [] );
  const [ currentDiary, setCurrentDiary ] = useState( null );

  // UI feedback states
  const [ addViewActive, setAddViewActive ] = useState( false );
  const [ editViewActive, setEditViewActive ] = useState( false );
  const [ settingsViewActive, setSettingsViewActive ] = useState( false );
  const [ notify, setNotify ] = useState( { type:'pending', msg:'caricamento...' } );

  const [ update, setUpdate ] = useState( false );





  // do on land once
  useEffect( () => {
    TC_switching_fetch (
      { op:"timecapsule_get_data", tcToken:_tokens.tc },
      `tcData_${_tokens.tc}`,
      _tokens.tc,
      config.store_basedata
    ).then( data => {

      setTitle( data.title );
      setDescription( data.description );

    }).catch( err => {
      setNotify({ type:'error', msg:err.msg, err:err.err });
    });

    // get diary list ..................................
    TC_fetch(
      { op:"diary_list", tcToken:_tokens.tc }
      )
      .then( diaries => {
        // set storage data
        if ( diaries.status === "ok" ) {

          let _diaries = [];

          diaries.diaries.map((dy) => {
            if ( dy === 'x'){
              // pass...
            } else {
              _diaries.push({
                token: dy
              });
            }
          });

          setDiaries( _diaries );
          setNotify( null );

        } else {
          setNotify({ type:'error', msg:'no diaries' });
        }
      }).catch( err => { // cant list diaries
        setNotify({ type:'error', msg:'fetch error', err:err });
      });

      // get timecapsule size ..................................
      return () => {
        setTitle( "" );
        setDescription( "" );
        setThumb( null );
        setDiaries( [] );
        setCurrentDiary( null );
        setNotify( null );
      }

  }, [ update ] );




  // TEMPLATE ------------------------------------------
  return (
    <>
    { logged == true && <div className="main-view-container view-main">

      < Spoiler_settings 
        show = { settingsViewActive }
        _toggle = { () => switch_view( 'settings' ) }
        />

      
      <div className="center-container">

        < Dash_header title={ title } />

        <div className="cards-container">

          <div id="top">
            < Card_add
              text={ <div>Crea <br/> Diario</div> }
              _action={ () => switch_view( 'add' ) }
              />

            < Bubble_Message style='solo' message={ description } />
            < State_Notify notify={ notify } />
          </div>

          { diaries.map( ( dy ) => (
            < Card_Diary
              token   = { { tc:_tokens.tc, dy:dy.token } } 
              _action = { () => {} }
              _edit   = { ( p ) => switch_view( 'edit', p ) }
              _delete = { ( p ) => _delete( p ) }
              update  = { update }
              key     = { dy.token }
              />
            ) ) }

            { diaries.length === 0 &&
              < Card_Empty scope="diari" />
            }

          <div className="tc-card-bottom-filler"></div>
        </div>

        < Create_view
          type     = "diario"
          title    = "Crea Diario"
          _action  = { () => switch_view( 'add' ) }
          _create  = { ( p ) => _create( p ) }
          show     = { addViewActive }
          context  = { { tc:_tokens.tc } }
          />

        { currentDiary &&
          < Edit_view
            type     = "diario"
            title    = "modifica Diario"
            _action  = { () => switch_view( 'edit', currentDiary ) }
            _edit    = { ( p ) => _edit( p ) }
            show     = { editViewActive }
            context  = { currentDiary }
            />
        }

        <div className="cont-bottom">
          < Dash_nav_comp
            backLink  ="/dash"
            type      = "home"
            _action   = { () => scroll_to_element( 'top' ) }
            _settings = { () => switch_view( 'settings' ) }
            />
        </div>

      </div>
    </div> 
  }
  </>
  );

  function _create ( p )
  {
    return new Promise(( resolve, reject ) => {
      if ( !p ) { reject( 'no param' ); }
      // generate data objects
      let _data = { title:p.title, description:p.description };
      let _thumb = { thumb:p.thumb }

      // try encrypt diary data ........................... ENCRYPT
      TC_encrypt( JSON.stringify( _data ), secureLocalStorage.getItem( 'rc'+p.tokens.tc ) ).then( enc_data => {

        // try encrypt diary data ........................... ENCRYPT
        TC_encrypt( JSON.stringify( _thumb ), secureLocalStorage.getItem( 'rc'+p.tokens.tc ) ).then( enc_thumb => {

          // size check before uploading .................... CHECK
          let current_storage_size = secureLocalStorage.getItem( 'tcSize'+p.tokens.tc );

          if ( current_storage_size == null ) {
            resolve([ 0, 'cant get storage size']);
          }

          if ( current_storage_size + ( (enc_data.length) + (enc_thumb.length) ) >= 50000000 ) {
            resolve([ 0, 'no space' ]);

          } else {
            // send data to endpoint .......................... FETCH
            TC_fetch(
              {
                op      : "diary_add",
                tcToken : p.tokens.tc,
                dyData  : enc_data,
                dyThumb : enc_thumb
  
              }).then( re => {
              if ( re.status === "ok" ) {
                // store data for fast retrieval .....................
                TC_Store( 'set', `dyData_${ re.token }`, enc_data ).then( re => {
                  if ( re !== true ) {
                    console.log(' can\'t store on local storage.');
                  }
                });
  
                // let totalSize = ( enc_data.length + enc_thumb.length ) * 2;
                // console.log( totalSize / 1000000 );
  
                // close add view.
                switch_view( 'add' );
                triggerUpdate();
                resolve ([ 1 ]);
  
              }
            // Handle errors .................................. ERRORS
            }).catch(err => {
              resolve ([ 0, 'fetch error', err ]);
            });
          }
        }).catch( err => {
          resolve ([ 0, 'encrypt error', err ]);
        });
      }).catch( err => {
        resolve ([ 0, 'encrypt error', err ]);
      });
    });
  }
  
  function _edit ( p )
  {
    return new Promise(( resolve, reject ) => {
      if ( !p ) { reject( 'no param' ); }
      // handle no changes ......................
      if ( !p.titleUpdated && !p.descriptionUpdated && !p.thumbUpdated ) {
        switch_view( 'edit' );
        resolve ([ 1, 'no changes' ]);
      }

      // if desc and thumb updated
      if ( ( p.titleUpdated || p.descriptionUpdated ) && p.thumbUpdated ) {
        store_data( p ).then( re => {
          store_thumb( p ).then( re => {
            switch_view( 'edit' );
            triggerUpdate();
            resolve( re );
          }).catch( err => {
            reject( err );
          });
        }).catch( err => {
          reject( err );
        });
      }

      // if only desc updated
      if ( ( p.titleUpdated || p.descriptionUpdated ) && !p.thumbUpdated ) {
        store_data( p ).then( re => {
          switch_view( 'edit' );
          triggerUpdate();
          resolve( re );
        }).catch( err => {
          reject( err );
        });
      }

      // in only thumb updated
      if ( ( !p.titleUpdated || !p.descriptionUpdated ) && p.thumbUpdated ) {
        store_thumb( p ).then( re => {
          switch_view( 'edit' );
          triggerUpdate();
          resolve( re );
        }).catch( err => {
          reject( err );
        });
      }

    });
  }

  function _delete ( p )
  {
    return new Promise(( resolve, reject ) => {
      if ( !p ) { reject( 'no param' ); }

      TC_switching_remove(
        { op:"diary_remove", tcToken:p.tokens.tc, dyToken:p.tokens.dy },
        `dyData_${p.tokens.dy}`
      ).then( re => {
        
        triggerUpdate();
        resolve ([ 1, 'diary removed', re ]);

      }).catch( err => {
        resolve([ 2, 'Ci sono ancora memorie', err ]);
      });

    });
  }

  // data will be stored always locally
  function store_data ( p ) {
    return new Promise(( resolve, reject ) => {
      TC_switching_store(
        { title:p.title, description:p.description },
        { op:"diary_update_data", tcToken:p.context.tc, dyToken:p.context.dy },
        `dyData_${p.context.dy}`,
        p.context.tc
      ).then( re => {
        resolve ([ 1 ]);
      }).catch( err =>  {
        reject ([ 0, 'Edit error', err ]);
      });
    });
  }

  // diary thumb will be stored always not locally
  function store_thumb ( p ) {
    return new Promise(( resolve, reject ) => {
      TC_switching_store(
        { thumb:p.thumb },
        { op:"diary_update_thumb", tcToken:p.context.tc, dyToken:p.context.dy },
        `dyThumb_${p.context.dy}`,
        p.context.tc,
        false
      ).then( re => {
        resolve ([ 1 ]);
      }).catch( err =>  {
        reject ([ 0, 'Edit error', err ]);
      });
    });
  }

  function switch_view ( view, _context ) {
    switch ( view ) {
      default:
      case 'add':
        addViewActive ? setAddViewActive( false ) : setAddViewActive( true ) ;
        if ( addViewActive ) {
          triggerUpdate();
        }
        break;
      case 'edit':
        setCurrentDiary( _context );
        editViewActive ? setEditViewActive( false ) : setEditViewActive( true );
        if ( editViewActive ) {
          triggerUpdate();
        }
        break;
      case 'settings':
        settingsViewActive ? setSettingsViewActive( false ) : setSettingsViewActive( true );
        break;
    }
  }

  function triggerUpdate ()
  {
    update ? setUpdate( false ) : setUpdate( true );
  }

}