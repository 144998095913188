import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

// just go home and reset url.
function Go_home () {
  const navigate = useNavigate();

  useEffect( () => {
    navigate('/');
  }, [] );

  return ( <p> meh... </p> )
}

export default Go_home;