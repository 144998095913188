// images and icons
import icon_sad   from "../../img/icon_sad.png";
import icon_smile from "../../img/icon_smile.png";

function Spoiler_delete ({ scope, _keep, _delete }) {

  function scopeText () {
    switch ( scope ) {
      case 'memoria':
        return (
          <>una <b>Memoria</b></>
        );
        break;
        
        case 'diario':
          return (
            <>un <b>Diario</b></>
          );
        break;
    
      default:
        break;
    }
  }

  return(
    <div className="confirm-spoiler-container">
      <div className="confirm-text">
        Stai per cancellare { scopeText() }! il processo <b>non è reversibile</b>
        <p className="my-1">Clicca sulla faccina triste per <b>cancellarlo</b></p>
        <p className="my-1">Clicca sulla faccina felice per <b>mantenere</b></p>
      </div>

      <div className="tc-icon-main" onClick={ () => _keep() }>
        <img src={ icon_smile } alt="mantieni" />
      </div>

      <div className="tc-icon-delete" onClick={ () => _delete() }>
        <img src={ icon_sad } alt="elimina" />
      </div>

    </div>
  );
}

export default Spoiler_delete;
