import { useState } from "react";

// functional
import Spoiler_settings from "../components/spoilers/Spoiler_settings";
import Dash_nav_comp    from "../components/dash/Dash_nav_comp";

function Info_view () {

  const [ settingsViewActive, setSettingsViewActive ] = useState( false );


  return (
    <div className="main-view-container view-main">

      < Spoiler_settings 
        show = { settingsViewActive }
        _toggle = { () => switchSettingsView() }
        />

      <div className="simple-container p-4">

      <h3>Informazioni</h3>

      <p>L'app Time Capsule è attualmente in fase beta e in continuo sviluppo. 
      Stiamo lavorando costantemente per migliorare la tua esperienza e aggiungere 
      nuove funzionalità. Grazie per il tuo supporto e la tua pazienza mentre 
      continuiamo a crescere insieme.</p>

      <p>Ti invitiamo a scoprire come funziona e a seguire i nostri semplici 
      step di guida per sfruttare al meglio tutte le potenzialità di Time Capsule.</p>

      <a href="https://dreamit.shop/pages/time-capsule">Più info su Dreamit.shop</a>

      </div>

      <div className="cont-bottom">

        <Dash_nav_comp
          backLink="/dash" 
          type = "home"
          _action = { null }
          _settings = { () => switchSettingsView() }
          />

      </div>
    </div>

  );

  function topScroll () {
    document.getElementById('top').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  function switchSettingsView ( )
  {
    settingsViewActive ? setSettingsViewActive( false ) : setSettingsViewActive( true );
  }

}

export default Info_view;