/* - 2024 - expre        ,-.----.
                        \    /  \   __  ,-.
             ,--,  ,--, |   :    |,' ,'/ /|
     ,---.   |'. \/ .`| |   | .\ :'  | |' | ,---.
    /     \  '  \/  / ; .   : |: ||  |   ,'/     \
   /    /  |  \  \.' /  |   |  \ :'  :  / /    /  |
  .    ' / |   \  ;  ;  |   : .  ||  | ' .    ' / |
  '   ;   /|  / \  \  \ :     |`-';  : | '   ;   /|
  '   |  / |./__;   ;  \:   : :   |  , ; '   |  / |
  |   :    ||   :/\  \ ;|   | :    ---'  |   :    |
   \   \  / `---'  `--` `---'.|           \   \  /
    `----'                `---`            `----'
    helper lybraries from:
    image compressor - browser-image-compression
    qr scanner       - react-qr-scanner
  //////////// -- Time-Capsule App -- ///////////*/

import './App.css';
import Login_view from "./views/Login_view"
import Splash_view from "./views/Splash_view";
import Register_view from './views/Register_view';
import Dash_view from './views/Dash_view';
import Timecapsule_view from './views/Timecapsule_view';
import Diary_view from './views/Diary_view';
import Memory_view from './views/Memory_view';
import Go_home from './views/Go_home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Coming_soon_view from './views/Coming_soon_view';
import About_view from './views/About_view';
import Privacy_view from './views/Privacy_view';
import Info_view from './views/Info_view ';


function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>

          <Route 
            exact 
            path="/" 
            element={<Splash_view />} 
            />

          <Route 
            exact 
            path="/:tokens" 
            element={<Splash_view />} 
            />
            
          <Route 
            exact 
            path="/login/:tokens" 
            element={<Login_view />} 
            />
            
          <Route 
            exact 
            path="/register/:tokens"
            element={<Register_view />} 
            />
            
          <Route 
            exact 
            path="/dash" 
            element={<Dash_view />} 
            />
            
          <Route 
            exact 
            path="/timecapsule/:tokens" 
            element={<Timecapsule_view />} 
            />
            
          <Route 
            exact 
            path="/diary/:tokens" 
            element={<Diary_view />} 
            />

          <Route 
            exact 
            path="/memory/:tokens" 
            element={ <Memory_view title="test title" date="10-10-2024" /> }
            />

          <Route
            exact
            path="/coming_soon/:tokens"
            element={ <Coming_soon_view /> }
            />

          <Route
            exact
            path="/about"
            element={ <About_view /> }
            />

          <Route
            exact
            path="/info"
            element={ <Info_view /> }
            />

          <Route
            exact
            path="/privacy"
            element={ <Privacy_view /> }
            />

          <Route
            path="*" 
            element={ <Go_home /> } 
            />

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
