import secureLocalStorage from "react-secure-storage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// images and icons
import icon_close from "../../img/icon_close.png";
import icon_user  from "../../img/icon_user.png";


function Spoiler_settings ({ show, _toggle }) {

  const nav = useNavigate();
  const [ userData, setUserData ] = useState( null );

  useEffect( ( ) => {
    let user_data = secureLocalStorage.getItem('userData');
    if ( user_data ) {
      setUserData ( JSON.parse( user_data ) );
    }
  }, []);


  return (

    <div className={ show ? "settings-fill cc-fadein" : "hidden"} >
        <div className="settings-main above" >
      
        <div className="icon" onClick={ () => _toggle() } >
          <img src={ icon_close } alt="x" />
        </div>

        <div className="title">
          Impostazioni
        </div>

        <div className="user">
          { userData && <div className="uname"> { userData.user_name } </div> }
          <div>V 0.9 BETA </div>
        </div>

        <div className="section" onClick={ () => nav('/dash') } >
          torna a home
        </div>

        <div className="section" onClick={ () => nav('/about') } >
          chi siamo
        </div>

        <div className="section" onClick={ () => nav('/privacy') } >
          privacy
        </div>

        <div className="section" onClick={ () => nav('/info') } >
          informazioni
        </div>

        <div className="mini-info">
          L'app Time Capsule è attualmente in fase beta e in continuo sviluppo. 
          <p>Stiamo lavorando costantemente per migliorare la tua esperienza e 
          aggiungere nuove funzionalità.</p> <p>Grazie per il tuo supporto e la tua 
          pazienza mentre continuiamo a crescere insieme.</p>
        </div>

      </div>
    </div>

  );

  function handleLogout ( ) {
    console.log('logging out');
  }

}

export default Spoiler_settings;