import { Link, useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useEffect, useState } from "react";
import { app_src } from "../src";

// functional
import { TC_fetch, TC_precheck } from "../actions/TC_fetch";
import TC_decrypt                from "../actions/TC_decrypt";
import State_Notify              from "../components/props/State_Notify";
import TCInput                   from "../components/dash/TCInput";

// images and icons
import main_logo from "../img/main_logo.webp";




function Login_view () {

  // get parameters
  const { tokens } = useParams();

  // user data
  const [userName, setUserName] = useState('');
  const [userPass, setUserPass] = useState('');
  // alerts
  const noAlert = { state : null, msg : "" };
  const [ userName_alert, setUserName_alert ] = useState( noAlert );
  const [ userPass_alert, setUserPass_alert ] = useState( noAlert );

  // UI feedback states
  const[ notify, setNotify ] = useState( null );

  const navigate = useNavigate();




  useEffect( () => {
    setNotify({ type:'pending', msg:'caricamento...' });

    if ( tokens != null ) {

      TC_precheck(
        { op:"timecapsule_precheck", tcToken:tokens }
      ).then( re => {
  
        switch ( app_src.state ) {

          case 'normal':
            // proceed ...
            setNotify( null );
            break;

          case 'coming_soon':
            navigate( `/coming_soon/${tokens}` );
            setNotify( null );
            break;

          case 'maintenance':
            navigate( `/maintenance/${tokens}` );
            setNotify( null );
            break;
        
        }
  
      }).catch( err => {
        setNotify({ type:'error', msg:'precheck error', err:err });
      })
    } else { // null token !!
      navigate("/");
    }
  }, [] );




  return (
    <div className="splash-view-container bg-main">
      <div className="bg-overlay-caps splash-bg-fix"></div>
      <div className="center-container">

        <div className="cont-top cont-top-splash">
          <img alt="logo" className="tc-full-logo" src={main_logo} />
        </div>
        
        <div className="cont-in">

          <form className="fill" onSubmit={ handleSubmit }>

            <TCInput name="nome utente" type="text" value={ userName }
              setValue = { setUserName }
              setAfter = { () => {} }
              enabled  = { true }
              alert    = { userName_alert }
              />

            <TCInput name="password" type="password" value={ userPass }
              setValue = { setUserPass }
              setAfter = { () => {} }
              enabled  = { true }
              alert    = { userPass_alert }
              />

          </form>

          <Link to={ `/register/${tokens}` } >
            <p>Non sono ancora registrato</p>
          </Link>

        </div>

        <div className="cont-bottom cont-bottom-splash">
          <button className="btn-tc-big" onClick={ handleSubmit }> Entra </button>
        </div>

        < State_Notify notify={ notify } />
        
      </div>
    </div>
  );




  function handleSubmit ()
  {
    setNotify({ type:'pending', msg:'caricamento...' });
    let tcCount = 1;    // for timecapsule looping.

    // try to log user ...................................................... FETCH user_log
    TC_fetch(
      { op: "user_log", userName, userPass }

      ).then(data => {
        if ( data.status === "ok" ) { // all fine
          secureLocalStorage.setItem( "userData", JSON.stringify( data.user_data ) );
          // IF logged - list timecapsules .................................. FETCH timecapsule_list
          TC_fetch(
            { op:"timecapsule_list", user_id:data.user_data.id }

            ).then(data => {
              // set storage data
              if ( data.status === "ok" ) { // all fine
                // Precheck all listed timecapsules ......................... LOOP user timecapsules
                data.timecapsule.map( tc => {
                  // avoid first element "x"
                  if ( tc !== 'x'){
                    // Precheck Timecapsule ................................. FETCH timecapsule_precheck in loop
                    TC_fetch(
                      { op:"timecapsule_precheck", tcToken:tc }
                      ).then(  precheckData => {
                        if (  precheckData.status === "ok" ) { // all fine
                        // decrypt secret with user password ............... DECRYPT secret
                        TC_decrypt(
                          precheckData.timecapsule.timecapsule_pri,
                          userPass
                          ).then( secret => {
                            secureLocalStorage.setItem( 'rc'+tc, secret ); // store data.
                            // forward user to dash only if all timecapsules processed.
                            if ( tcCount == data.timecapsule.length ) { // ............ END
                              setNotify( null );
                              navigate('/dash');
                            }
                            tcCount++;

                          // Handle errors ............................................ ERROR WALL !!
                          }).catch( err => { // error decrypting data !!
                            setNotify({ type:'error', msg:'decryption error', err:err });
                          });
                        } else { // Timecapsule precheck responded KO !!
                          setNotify({ type:'error', msg:'precheck error' });
                        }
                      }).catch( err => { // Timecapsule precheck error !!
                        setNotify({ type:'error', msg:'timecapsule precheck fetch error', err:err });
                      });
                  } // if not x. just a check <<
                }); // timecapsules map end <<
              } else { // Timecapsule list responded KO !!
                setNotify({ type:'error', msg:'nessun timecapsule' });
              }
            }).catch(err => { // list timecapsules error !!
              setNotify({ type:'error', msg:'timecapsule list fetch error', err:err });
            });
        } else { // log user responded KO !!
          setNotify({ type:'error', msg:'dati errati' });
        }
      }).catch(err => { // log user error !!
        setNotify({ type:'error', msg:'log in error', err:err });
      });

  }

}

export default Login_view;
