import { Link } from "react-router-dom";

// images and icons
import icon_plus     from "../../img/icon_dark_plus.png";
import icon_settings from "../../img/icon_settings.png";
import icon_qr       from "../../img/icon_qrcode.png";
import icon_diary    from "../../img/icon_diary.png";
import icon_home     from "../../img/icon_home.png";
import icon_tc       from "../../img/icon_tc.png";

export default function Dash_nav_comp ({ backLink, type, _action, _settings }) {

  function handleSettings () {
    console.log('settings');
  }

  return (
    <div className="dash-nav rounded-min">

      <div className="back_btn">
        <Link to={ backLink } >
          { type === 'timecapsule' && <img src={ icon_tc } alt="timecapsule" /> }
          { type === 'diary' && <img src={ icon_diary } alt="diario" /> }
          { type === 'home' && <img src={ icon_home } alt="home" /> }
          { type === 'login' && <img src={ icon_qr } alt="home" /> }
        </Link>
      </div>

      { _action &&
      <div className="action_btn" onClick={ () => _action() }>
        <img src={ icon_plus } alt="settings" />
      </div>
      }

      <div className="setting_btn" onClick={ () => _settings() }>
        <img src={ icon_settings } alt="settings" />
      </div>

    </div>
  );
  
}