// images and icons
import icon_image  from "../../img/spoiler_image_icon.png";
import icon_remove from "../../img/spoiler_delete_icon.png";

function Spoiler_edit ({ fu1, fu2 }) {
  return (
    <div className="spoiler-cont">
      { fu1 && <img className="spoiler-icon" onClick={ () => fu1() } src={ icon_image } alt="modifica" /> }
      { fu2 && <img className="spoiler-icon" onClick={ () => fu2() } src={ icon_remove } alt="rimuovi" /> }
    </div>
  );
}

export default Spoiler_edit;