import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { TC_fetch } from "../actions/TC_fetch";
import useUserdata from "./useUserdata";

function useAuth ( navigate, force=null )
{
  // behaviour simulation.
  
  const [ logState, setLogState ] = useState( false );
  const userData = useUserdata();
  const nav = useNavigate(); // for user routing
  
  useEffect( () => {
    
    if ( force != null ) {
      console.log('force', force );
      return force;
    }

    if ( userData.user_name == null && userData.access_token == null ) {
      console.log('userdata null');
      nav('/');
    }

    // make request
    TC_fetch(
      {
        op : "user_auth",
        userName : userData.user_name,
        userToken : userData.access_token }
      ).then(data => {
        // check response
        if ( data.status === "ok" ) {
          setLogState( true );
          // route user if navigate is set
          if ( navigate !== false ) {
            nav( navigate );
          }
        // by default go to root. for testing
        } else {
          setLogState( false );
          nav("/");
        }
      })
      .catch(err => { console.log( err ) });
  }, [ ] );

  return logState;
}

export default useAuth;
