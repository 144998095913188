import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { config } from "../src";

// functional
import { TC_precheck, TC_switching_fetch } from "../actions/TC_fetch";
import Dash_nav_comp    from "../components/dash/Dash_nav_comp";
import Date_since       from "../components/props/Date_since";
import { to_blob }      from "../actions/File_Process";
import Spoiler_settings from "../components/spoilers/Spoiler_settings";
import State_Notify     from "../components/props/State_Notify";
import Bubble_Message from "../components/bubbles/Bubble_Message";
import useAuth          from "../hooks/useAuth";




function Memory_view () {

  // preliminary operations
  const logged = useAuth();
  const { tokens } = useParams();

  const [ date, setDate ] = useState( null );
  const [ title, setTitle ] = useState( null );
  const [ description, setDescription ] = useState( null );
  const [ memory, setMemory ] = useState ( null );
  const [ memoryType, setMemoryType ] = useState( null );

  // UI states
  const [ notify, setNotify ] = useState( null );
  const [ settingsViewActive, setSettingsViewActive ] = useState( false );
  
  let tSplit = tokens.split(':');
  const _tokens = { tc:tSplit[0], dy:tSplit[1], mem:tSplit[2] }
  
  useEffect( () => {
    setNotify({ type:'pending', msg:'caricamento...' });

    if ( tSplit.length > 3 ){
      setNotify({ type:'error', msg:'token count error' });
    }

    // precheck memory ............................
    TC_precheck(
      { op:"memory_precheck", memToken:_tokens.mem }
    ).then( re => {

      // set base data in UI
      setDate( re.memory.created );
      setMemoryType( re.memory.data_type );
      showMemory( re.memory.data_type );
      setNotify( null );
      
    }).catch( err => {
      setNotify({ type:'error', msg:err.msg, err:err.err });
    });
    
    // get memory data
    TC_switching_fetch(
      { op:"memory_get_data", tcToken:_tokens.tc, dyToken:_tokens.dy, memToken:_tokens.mem },
      `memData_${_tokens.mem}`,
      _tokens.tc,
      config.store_basedata
    ).then( data => {
      
      // set base data in UI
      setTitle( data.title );
      setDescription( data.description );

    }).catch( err => {
      setNotify({ type:'error', msg:err.msg, err:err.err });
    });

  }, [ ] );




  return (
    <>
    { logged == true &&
    <>
    <div className="memory-container bg-main">
    <div className="bg-overlay-memory"></div>
      < Spoiler_settings 
        show = { settingsViewActive }
        _toggle = { () => switch_view( 'settings' ) }
        />

      <div className="p-2 above">
        <div className="memory-header">
          <div className="memory-title text-light">{ title }</div>
          <Date_since date={ date } />
        </div>

        { memoryType === 'img' &&
        <div className="tc-card-memory">
          < img
            src = { memory }
          />
        </div>
        }

        { memoryType === 'vid' &&
          <div  className="tc-card-memory">
            <video controls>
              { memory && <source src={ memory[0] } type={ memory[1] } />}
            </video>
          </div>
        }

        < Bubble_Message style='full' message={ description } />
        < State_Notify notify={ notify } />
      </div>



    </div>

    <div className="cont-bottom">
      < Dash_nav_comp
        backLink={ `/diary/${tokens}` }
        type = "diary"
        _action={ null }
        _settings = { () => switch_view( 'settings' ) }
        />
    </div>
    </>
    }
    </>
  );




  function showMemory ( type )
  {
    get_memory().then( data => {
      if ( type === 'img' ) {
        updateImageMemory( data.memory );
      } else {
        updateVideoMemory( data.memory );
      }
    }).catch( err => {
      setNotify({ type:'error', msg:err.msg, err:err.err });
    });
  }

  function get_memory ()
  {
    return new Promise(( resolve, reject ) => {
      TC_switching_fetch(
        { op:"memory_get_content", tcToken:_tokens.tc, dyToken:_tokens.dy, memToken:_tokens.mem },
        `memory_${_tokens.mem}`,
        _tokens.tc,
        config.store_content
      ).then( data => {
        resolve( data );
      }).catch( err => {
        reject( err );
      });
    });
  }

  function updateImageMemory ( dataString )
  {
    let memory_data = to_blob( dataString );
    let t_file = new File( [ memory_data[0] ], 'thumb'+tokens.dy, { type:memory_data[1] });
    var file_Url = URL.createObjectURL( t_file );
    setMemory( file_Url );
  }

  function updateVideoMemory ( dataString )
  {
    let memory_data = to_blob( dataString );
    let url = URL.createObjectURL( memory_data[0] );
    setMemory([ url, memory_data[1] ]);
  }

  function switch_view ( view, _context ) {
    switch ( view ) {
      default:
      case 'settings':
        settingsViewActive ? setSettingsViewActive( false ) : setSettingsViewActive( true );
        break;
    }
  }

}

export default Memory_view;
