export const Texts_ita = {

  // italian language
    dash:{
      head_title  : <p>Le tue Time Capsule</p>,
      add_tc      : <div>Aggiungi <br/> Timecapsule</div>,
      connect_tc  : <p>Associa Timecapsule</p>,
      activate_tc : <p>Attiva Timecapsule</p>,
      modify_tc   : <p>Modifica Timecapsule</p>,
    },

    timecapsule:{

    },

    diary:{

    },

    memory:{

    }

}

export const Texts_eng = {

  // italian language
    dash:{
      head_title: <p>Le tue Time Capsule</p>,
      add_tc: <div>Aggiungi <br/> Timecapsule</div>,
    },

    timecapsule:{

    },

    diary:{

    },

    memory:{

    }

}