import useUserdata from "../../hooks/useUserdata";
import { app_info } from "../../src";


function Dash_header ({ title }) {
  
  const userData = useUserdata();

  return (
    <div className="dash-header">
      <div className="title">{ title }</div>
      <div className="title-name">
        { userData && <div className="username"> { userData.user_name } </div> }
        <div className="version">{ app_info.vers }</div>
      </div>
    </div>
  );
}

export default Dash_header;