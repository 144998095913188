import { useEffect, useState } from "react";

// functional
import { to_blob, Image_process } from "../actions/File_Process";

// images and icons
import icon_close from "../img/icon_close.png";
import State_Notify from "../components/props/State_Notify";




/**
 * 
 * @param { string } type association type - associate or activate for now
 * @param { string } title main window title 
 * @param { function } _action confirm button function ( from parent )
 * @param { boolean } show main window state ( visible or not - fading in or out) 
 * @returns connect view html template.
 * 
 * connects a new timecapsule to subscription
 * _action flow:
 * scan first qr ->
 * set title ->
 * scan security qr ->
 * approve ->
 * confirm.
 * 
 */
export default function Edit_view ({ type, title, _action, _edit, show, context }) {
  // utility states
  const [ firstRun, setFirstRun ] = useState( true );

  // description states
  const [ newTitle, setNewTitle ] = useState( '' );
  const [ newDescription, setNewDescription ] = useState( '' );
  const [ newThumb, setNewThumb ] = useState( '' );  // low res image for thumb generation

  const [ titleUpdated, setTitleUpdated ] = useState( false );
  const [ descriptionUpdated, setDescriptionUpdated ] = useState( false );
  const [ thumbUpdated, setThumbUpdated ] = useState( false );
  const [ cover, setCover ] = useState( null );        // real time cover preview
  
  // UI feddback states
  const [ notify, setNotify ] = useState({ type:'pending', msg:'caricamento...'});




  useEffect( () => {
    // set and show current data
    setNewTitle( context.title );
    setNewDescription( context.description );
    if ( type !== 'memoria' ) {
      updateThumb( context.thumb );
      setNotify( null );
    }
  }, [ context ] );




  return(
    <div className={ show ? "cont-fill cc-fadein above" : firstRun ? "cont-fill hidden above" : "cont-fill cc-fadeout above" } >
      <div className={ show ? "card-create cc-fadein above" : firstRun ?  "card-create hidden above" : "card-create cc-fadeout above" } >
        <div className="bg-overlay-caps-dark"></div>

        <div className="cc-title-container">
          <div className="cc-title">{ title }</div>
          <div className="tc-icon" onClick={ () => _action() }>
            <img src={ icon_close } alt="x" />
          </div>
        </div>

        <div className="cc-body-container">

          { type === "timecapsule" &&
          <>
            <label htmlFor="title"> Titolo: </label>
            <input 
              name="title" 
              type="text" 
              placeholder={ "nome " + type }
              onChange={ (e) => {setNewTitle( e.target.value ); setTitleUpdated(true)} }
              value={ newTitle }
              />

            <label htmlFor="cover">Copertina</label>
            <input
              className="cc-cover rounded-min"
              name="cover"
              style={{
                backgroundColor: 'rgba(59,47,157,0.5)',
                backgroundImage: `url(${cover})`
              }}
              type="file"
              onChange={ (e) => {processCover( e.target.files); setThumbUpdated(true)} }
              />

            <label htmlFor="description"> Descrizione breve: </label>
            <textarea
              name="description"
              rows="4"
              maxLength="256"
              placeholder={ "descrizione " + type }
              onChange={ (e) => {setNewDescription( e.target.value ); setDescriptionUpdated(true)} }
              value={ newDescription }
              />
          </>
          }

          { type === "diario" &&
          <>
            <label htmlFor="title"> Titolo: </label>
            <input 
              name="title" 
              type="text" 
              placeholder={ "nome " + type }
              onChange={ (e) => {setNewTitle( e.target.value ); setTitleUpdated(true)} }
              value={ newTitle }
              />

            <label htmlFor="cover">Copertina</label>
            <input
              className="cc-cover rounded-min"
              name="cover"
              style={{
                backgroundColor: 'rgba(59,47,157,0.5)',
                backgroundImage: `url(${cover})`
              }}
              type="file"
              onChange={ (e) => {processCover( e.target.files); setThumbUpdated(true)} }
              />

            <label htmlFor="description"> Descrizione breve: </label>
            <textarea
              name="description"
              rows="4"
              maxLength="256"
              placeholder={ "descrizione " + type }
              onChange={ (e) => {setNewDescription( e.target.value ); setDescriptionUpdated(true)} }
              value={ newDescription }
              />
          </>
          }

          { type === "memoria" &&
          <>
            <label htmlFor="title"> Titolo: </label>
            <input 
              name="title" 
              type="text" 
              placeholder={ "nome " + type }
              onChange={ (e) => {setNewTitle( e.target.value ); setTitleUpdated(true)}}
              value={ newTitle }
              />

            { context.subtype && context.subtype !== 'txt' &&
            <>
              <label htmlFor="description"> Descrizione breve: </label>
              <textarea
                name="description"
                rows="4"
                maxLength="256"
                placeholder={ "descrizione " + type }
                onChange={ (e) => { setNewDescription( e.target.value ); setDescriptionUpdated(true) } }
                value={ newDescription }
                />
            </>
            }
          </>
          }

          < State_Notify notify={ notify } />
          
        </div>

        <div className="cc-foot">
          <button onClick={ () => processEdit() } className="btn-tc-big btn-tc-cc-confirm">MODIFICA</button>
        </div>

      </div>
    </div>
  );




  function processEdit ()
  {
    switch( type ){

      case 'timecapsule':
      case 'diario': // can update all...
        // ............ sending all old and new data for comparison
        _edit({
          title:newTitle,
          titleUpdated:titleUpdated,
          description:newDescription,
          descriptionUpdated:descriptionUpdated,
          thumb:newThumb,
          thumbUpdated:thumbUpdated,
          context:context.tokens
        });
        break;

      case 'memoria': // memories cant update thumbs or memory data...
        // ............. sending all old and new data for comparison
        _edit({
          title:newTitle,
          titleUpdated:titleUpdated,
          description:newDescription,
          descriptionUpdated:descriptionUpdated,
          context:context.tokens
        });
        break;
    }
  }

  function processCover ( images )
  {
    if ( !images ) {
      setNotify({ type:'error', msg:'no image to process' });
      return;
    }
    // set preview
    setCover( URL.createObjectURL( images[0] ) );
    // process compressed thumb and update NewThumb
    Image_process( images[0], true ).then( thumb => {
      setNewThumb( thumb.bytes );
    });

    return;
  }

  function updateThumb ( dataString )
  {
    if ( !dataString ) {
      setNotify({ type:'error', msg:'can\'t update thumb' });
      return;
    }
    let memory_data = to_blob( dataString );
    // update image, justincase
    let img = new File( [ memory_data[0] ], 'thumb'+context.tokens.dy, { type:memory_data[1] });
    // generate url for ui integration
    var imageUrl = URL.createObjectURL(img);
    // update image state. boom.
    setNewThumb( dataString );
    setCover( imageUrl );
    return;
  }

}