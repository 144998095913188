import secureLocalStorage from "react-secure-storage";
import { useState, useEffect } from "react";
import { Link }       from "react-router-dom";
import { config }     from "../../src";

// functional
import { TC_precheck, TC_switching_fetch } from "../../actions/TC_fetch";
import { to_blob }    from "../../actions/File_Process";
import { TC_size }    from "../../actions/TC_Storage";
import State_Notify   from "../props/State_Notify";
import Date_since     from "../props/Date_since";
import Spoiler_delete from "../spoilers/Spoiler_delete";
import Spoiler_edit   from "../spoilers/Spoiler_edit";
import friendlyToken  from "../Utils";

// images and icons
import icon_plus      from "../../img/icon_plus.png";
import icon_next      from "../../img/icon_next.png";
import icon_edit      from "../../img/icon_edit.png";
import Bubble_Message from "../bubbles/Bubble_Message";

/**
 * 
 * Thumb card has 2 states:
 * empty state and initialized state.
 * thumb card works for timecapsule type and diary type views.
 * 
 * for timecapsule states will be:
 *  activate timecapsule
 *  open timecapsule
 * 
 * for diary states will be:
 *  empty diary
 *  open diary
 * 
 * @arg token   -> token bundle for context
 * @arg _action -> card _action function from parent
 * @arg _edit   -> edit function from parent
 * @arg _delete -> delete function from parent
 * @arg update  -> triggers this view update from parent
 */
export default function Card_Diary ({ token, _action, _edit, _delete, update })
{
  // get data async for timecapsule diaries
  const [ state, setState ] = useState( "inactive" );
  const [ date, setDate ] = useState( "" );
  const [ spoilerState, setSpoilerState ] = useState ( false );

  const [ title, setTitle ] = useState( "" );
  const [ thumb, setThumb ] = useState( null );
  const [ rawThumb, setRawThumb ] = useState( null );
  const [ description, setDescription ] = useState ( "" );
  const [ storage, setStorage ] = useState( 0 );

  const [ memories, setMemories ] = useState ( [] );

  // UI feedback states
  const [ notify, setNotify ] = useState({ type:'pending', msg:'caricamento...' });
  const [ confirm, setConfirm ] = useState( null );


  

  useEffect( () => {
    // get diary general data ................................................ FETCH diary precheck
    TC_precheck(
      { op : "diary_precheck", dyToken : token.dy }
      ).then( re => {

        setDate( re.diary.created );

        switch ( re.diary.state ) {

          case 0: // inactive
            setState( "inactive" );
            setNotify( null );
          break;

          case 1: // active
            setState( "active" );
            setMemories( listContent( re.diary.memories ) );

            // get data
            TC_switching_fetch(
              { op:"diary_get_data", tcToken:token.tc, dyToken:token.dy },
              `dyData_${token.dy}`,
              token.tc,
              config.store_basedata

            ).then( data => {
              setTitle( data.title );
              setDescription( data.description );
              setNotify( null );

            }).catch( err => {
              setNotify({ type:'error', msg:err.msg, err:err.err });
            });

            // get thumb
            TC_switching_fetch(
              { op:"diary_get_thumb", tcToken:token.tc, dyToken:token.dy },
              `dyThumb_${token.dy}`,
              token.tc,
              config.store_thumb
              
            ).then( data => {
              updateThumb( data.thumb );
              setNotify( null );

            }).catch( err => {
              setNotify({ type:'error', msg:err.msg, err:err.err });
            });

          break;

        }
    }).catch( err => {
      setNotify({ type:'error', msg:err.msg, err:err.err });
    });
  }, [ update ] );




  return (
    <div>
      
      <div className="tc-card-header">
        <div className="card-title">{ title }
        </div>
        <Date_since date={ date } />
      </div>

      <>
        { state === "active" &&
        <> {/* diary active */}
          <div className="tc-card" >

            <div className="tc-card-test bg-main">
            <div className="bg-overlay-diary"></div>
              <>
                <Link to={ `/diary/${token.tc}:${token.dy}` }>
                  <div className="tc-icon tc-icon-main">
                  <img src={ icon_next } alt="prossimo" />
                  </div>
                </Link>

                <div className="tc-icon tc-icon-edit" onClick={ () => switch_spoiler() }>
                  <img src={ icon_edit } alt="modifica" />
                </div>

                <div className="thumb-card-cover thumb-borderline" style={{backgroundImage: `url(${thumb})`}} ></div>

                < Bubble_Message message={ description } />

                <div className="tc-date">
                  { memories && memories.length && memories.length } - memorie
                </div>
              </>

            </div>

            { confirm && 
              < Spoiler_delete
                scope   = { 'diario' }
                _keep   = { () => switchConfirm() }
                _delete = { () => handleDelete() }
                />
            }

            <div className={ spoilerState ? "tc-card-share h-slide-in" : "tc-card-share" }>
              < Spoiler_edit
                fu1 = { () => handleEdit() }
                fu2 = { () => switchConfirm() }
                />
            </div>

          </div>
        </>
        }

      </>

      < State_Notify notify={ notify } />

    </div>
  );




  function handleDelete ()
  {
    _delete({ tokens:token }).then( re => {
      if ( re[0] === 2 ) {
        setNotify({ type:'warning', msg:re[1] });
      }
      switchConfirm();
    }).catch( err => {
      handleWarning( err, 'delete' );
    });
  }

  function handleEdit ()
  {
    let re = _edit({ title:title, description:description, thumb:rawThumb, tokens:token });
  }

  function handleWarning ( response, scope )
  {
    if ( response ) {
      switch ( response[0] ) {

        case 0:
          setNotify({ type:'error', msg:response[1] });
          break;

        case 2:
          setNotify({ type:'error', msg:response[1], err:response[2] });
          break;
      
      }
    } else {
      setNotify({ type:'error', msg:`no response from ${ scope } operation` });
    }
  }

  function switch_spoiler ()
  {
    spoilerState ? setSpoilerState( false ) : setSpoilerState( true );
  }

  function switchConfirm ()
  {
    confirm ? setConfirm( false ) : setConfirm( true );
  }

  function listContent ( _content )
  {
    _content = _content.split("-");
    let content = [];

    _content.map(( element ) => {
      if ( element  === 'x'){
        // pass...
      } else {
        content.push({
          token: element
        });
      }
    });

    return content;
  }

  // to update
  function updateThumb ( dataString )
  {

    let memory_data = to_blob( dataString );

    let img = new File( [ memory_data[0] ], 'thumb'+token.dy, { type:memory_data[1] });
    // generate url for ui integration
    var imageUrl = URL.createObjectURL(img);
    // update image state. boom.
    setRawThumb( dataString );
    setThumb( imageUrl );
  }

}