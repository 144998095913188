async function TC_Fetch_Multipart ( endpoint, request, log_all = false )
{
  const formData = new FormData();

  for ( let [ field, val ] of Object.entries( request ) ){
    formData.append( field, val );
  }

  // if ( log_all ) { console.log(formData); }

    return fetch( endpoint, {
      method: 'POST',
      body: formData
    }).then(res => {
      if ( log_all ) { console.log( request ); }
      return res.json(); // pass result as json to second step

    }).then(data => {
      if ( log_all ) { console.log( data ); }
      return data;       // return data object.
    })

    .catch(err => {
      if ( log_all ) { console.log( err ); }
      return err;        // return error
    });
}

export default TC_Fetch_Multipart;
