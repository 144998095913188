function friendlyToken( token ) {
  let newToken = '';
  let len = 4;
  let counter = 0;
  for  ( let i = 0; i < token.length; i++ ) {
    if ( counter == len ) {
      newToken += ' - '+token[i];
      counter = 0;
    } else {
      newToken += token[i];
    }
    counter ++
  }
  return newToken;
}

export default friendlyToken;