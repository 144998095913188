import { useState } from "react";

import Spoiler_settings from "../components/spoilers/Spoiler_settings";
import Dash_nav_comp    from "../components/dash/Dash_nav_comp";

function About_view () {

  const [ settingsViewActive, setSettingsViewActive ] = useState( false );


  return (
    <div className="main-view-container view-main">

      < Spoiler_settings 
        show = { settingsViewActive }
        _toggle = { () => switchSettingsView() }
        />

      <div className="simple-container p-4">

        <h3>Chi siamo</h3>

        <p>Benvenuti in Time Capsule!</p>

        <p>Siamo un team appassionato di innovazione e ricordi, 
        nato dall’idea di rendere eterna ogni tua emozione. 
        Time Capsule è molto più di un semplice prodotto: 
        è un’esperienza unica che unisce tecnologia all’avanguardia e 
        design raffinato per conservare e proteggere i tuoi momenti più preziosi.</p>

        <p>Il nostro viaggio è iniziato con l'intuizione di Francesca, 
        la fondatrice di DreamIt, che ha sempre cercato di creare gioielli 
        capaci di racchiudere emozioni profonde e durature. Da questa visione, 
        è nata la tecnologia Time Capsule, integrata nei nostri gioielli Unico 
        per offrire un modo nuovo e magico di custodire i ricordi.</p>

        <p>Ogni giorno ci impegniamo a garantire la massima qualità e 
        cura nei nostri prodotti, utilizzando materiali di alta gamma e 
        lavorazioni artigianali. Siamo orgogliosi di essere un brand Made in Italy, 
        dove tradizione e innovazione si incontrano per creare pezzi unici e personalizzati.</p>

        <p>Time Capsule ti permette di caricare immagini, video e audio 
        dei tuoi momenti più belli, così da poterli rivivere ogni volta 
        che vuoi, semplicemente avvicinando il tuo dispositivo al gioiello. 
        È il nostro modo di aiutarti a conservare per sempre ciò che conta di più.</p>

        <p>Grazie per aver scelto di fare parte della nostra famiglia. 
        Siamo qui per accompagnarti in ogni passo del tuo viaggio 
        emozionale, aiutandoti a creare, conservare e celebrare i tuoi 
        ricordi più cari.</p>

        <p>Il Team di Time Capsule</p>

      </div>

      <div className="cont-bottom">

        <Dash_nav_comp
          backLink="/dash" 
          type = "home"
          _action = { null }
          _settings = { () => switchSettingsView() }
          />

      </div>
    </div>

  );

  function topScroll () {
    document.getElementById('top').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  function switchSettingsView ( )
  {
    settingsViewActive ? setSettingsViewActive( false ) : setSettingsViewActive( true );
  }

}

export default About_view;