import Bubble_Message from "../bubbles/Bubble_Message";

function Card_Empty ({ scope }) {
  return (
    <div className="cc-fadein">
      <div className="tc-card">
        <div className="tc-card memory-void">
          <div className="thumb-card-cover-void"></div>
          < Bubble_Message style='faded' message={`Non ci sono ancora ${scope} qui ...`} />
        </div>
      </div>
    </div>
  );
}

export default Card_Empty;
