import secureLocalStorage from "react-secure-storage";
import { useState, useEffect } from "react";
import { Link }       from "react-router-dom";
import { config }     from "../../src";

// functional
import { TC_precheck, TC_switching_fetch } from "../../actions/TC_fetch";
import { to_blob }    from "../../actions/File_Process";
import { TC_size }    from "../../actions/TC_Storage";
import Bubble_Message from "../bubbles/Bubble_Message";
import State_Notify   from "../props/State_Notify";
import Date_since     from "../props/Date_since";
import friendlyToken  from "../Utils"; 

// images and icons
import icon_plus from "../../img/icon_plus.png";
import icon_next from "../../img/icon_next.png";
import icon_edit from "../../img/icon_edit.png";

/**
 * 
 * Thumb card has 2 states:
 * empty state and initialized state.
 * thumb card works for timecapsule type and diary type views.
 * 
 * for timecapsule states will be:
 *  activate timecapsule
 *  open timecapsule
 * 
 * for diary states will be:
 *  empty diary
 *  open diary
 * 
 * @arg type -> timecapsule or diary
 * @arg _action -> card _action function from parent
 */
export default function Card_Timecapsule ({ token, _action, _edit, _delete, update }) {

  // get data async for timecapsule diaries
  const [ state, setState ] = useState( "inactive" );
  const [ date, setDate ] = useState( "" );
  const [ spoilerState, setSpoilerState ] = useState ( false );

  const [ title, setTitle ] = useState( "" );
  const [ thumb, setThumb ] = useState( null );
  const [ rawThumb, setRawThumb ] = useState( null );
  const [ description, setDescription ] = useState ( "" );
  const [ storage, setStorage ] = useState( 0 );

  const [ diaries, setDiaries ] = useState ( [] );

  // UI feedback states
  const [ notify, setNotify ] = useState({ type:'pending', msg:'caricamento...' });
  const [ confirm, setConfirm ] = useState( null );


  

  useEffect( () => {
    // fetch timecapsule data ...................... FETCH timecapsule precheck
    TC_precheck(
      { op : "timecapsule_precheck", tcToken : token.tc }
      ).then( re => {

        setDate( re.timecapsule.active );

        switch ( re.timecapsule.state ) {
          
          case 1: // associated but inactive ...
            setState( "inactive" );
            setTitle( friendlyToken( token.tc ) );
            setNotify({ type:'warning', msg:'questa timecapsule non è ancora attiva ...' });
          break;

          case 2: // active can be operated upon ...
            // update card state
            setState( "active" );
            setDiaries( listContent( re.timecapsule.diaries ) );

            // .................................... Switcing fetch
            // get data for timecapsule
            TC_switching_fetch(
              { op : "timecapsule_get_data", tcToken : token.tc },
              `tcData_${token.tc}`,
              token.tc,
              config.store_basedata
            ).then( data => {
              setTitle( data.title );
              setDescription( data.description );
              updateThumb( data.thumb );
              setNotify( null );

              // evaluate timecapsule occupied size
              TC_size( token.tc ).then( size => {
                // update timecapsule size on UI
                setStorage( size / 1000000 );
                // record size.
                secureLocalStorage.setItem( 'tcSize'+token.tc, size );
              }).catch( err => {
                setNotify({ type:'error', msg:err.msg, err:err.err });
              });

            }).catch( err => {
              setNotify({ type:'error', msg:err.msg, err:err.err });
            });
          break;
        }

    }).catch( err => {
      setNotify({ type:'error', msg:err.msg, err:err.err });
    });

  }, [ update ] );




  return (
    <div>

      <div className="tc-card-header">
        <div className="card-title">{ title }
        </div>
        < Date_since date={ date } />
      </div>

      <>
        { state === "active" &&
        <> {/* timecapsule active */}
          <div className="tc-card bg-main">
          <div className="bg-overlay-caps"></div>

            <Link to={ `/timecapsule/${token.tc}` } >
              <div className="tc-icon tc-icon-main">
                <img src={ icon_next } alt="prossimo" />
              </div>
            </Link>

            <div className="tc-icon tc-icon-edit" onClick={ () => _edit({ title:title, description:description, thumb:rawThumb, tokens:token }) }>
              <img src={ icon_edit } alt="icona modifica" />
            </div>

            <div className="thumb-card-cover thumb-borderline" style={{backgroundImage: `url(${thumb})`}} ></div>
            
            < Bubble_Message message={ description } />

            <div className="tc-date">
              { storage.toFixed(2) } mb / 50 mb - { diaries && diaries.length && diaries.length } diari
            </div>

          </div>
        </>
        }

        { state === "inactive" &&
        <>
          <div className="tc-card-mini-thumb-container tc-card bg-main">

            <div className="tc-icon tc-icon-1" onClick={ () => _action() }>
              <img src={ icon_plus } alt="icona più" />
            </div>

            <div className="thumb-card-inactive p-2">
              <h5 className="text-light">
                La tua <b>Time capsule</b> è ora associata al tuo account !
                <p><br /> Clicca nel pulsante "+" qui di fianco per attivarla subito. </p>
                </h5>
            </div>

          </div>
        </>
        }
      </>

      < State_Notify notify={ notify } />

    </div>
  );




  function handleDelete ()
  {
    _delete({ tokens:token }).then( re => {
      console.log( 'response', re);
      switchConfirm();
    }).catch( err => {
      handleWarning( err, 'delete' );
    });
  }

  function handleEdit ()
  {
    let re = _edit({ title:title, description:description, thumb:rawThumb, tokens:token });
  }

  function handleWarning ( response, scope )
  {
    if ( response ) {
      switch ( response[0] ) {

        case 0:
          setNotify({ type:'error', msg:response[1] });
          break;

        case 2:
          setNotify({ type:'error', msg:response[1], err:response[2] });
          break;
      
      }
    } else {
      setNotify({ type:'error', msg:`no response from ${ scope } operation` });
    }
  }

  function switch_spoiler ()
  {
    spoilerState ? setSpoilerState( false ) : setSpoilerState( true );
  }

  function switchConfirm ()
  {
    confirm ? setConfirm( false ) : setConfirm( true );
  }

  function listContent ( _content )
  {
    _content = _content.split("-");
    let locId = 0;
    let content = [];

    _content.map(( element ) => {
      if ( element  === 'x'){
        // pass...
      } else {
        content.push({
          token: element ,
          key: locId
        });
        locId ++;
      }
    });
    return content;
  }

  // to update
  function updateThumb ( dataString )
  {

    let memory_data = to_blob( dataString );

    let img = new File( [ memory_data[0] ], 'thumb'+token.dy, { type:memory_data[1] });
    // generate url for ui integration
    var imageUrl = URL.createObjectURL(img);
    // update image state. boom.
    setRawThumb( dataString );
    setThumb( imageUrl );
  }

}