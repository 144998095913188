import { useEffect, useState } from "react";

// funactional
import { to_blob, Image_process } from "../actions/File_Process";
import File_Process   from "../actions/File_Process";
import State_Notify   from "../components/props/State_Notify";

// images and icons
import icon_close from "../img/icon_close.png";


function Create_view ({ title, type, _action, _create, show, context }) {

  // UI feedback states ..............................................
  const [ firstRun, setFirstRun ] = useState( true );  // for animations control
  const [ notify, setNotify ] = useState( { type:'ok' } );

  // texts and contents ..............................................
  const [ currentTitle, setCurrentTitle ] = useState( '' );  // element title
  const [ currentDescription, setCurrentDescription ] = useState( '' );  //  element description
  
  // images ...........................................................
  const [ cover, setCover ] = useState( null );  // real time cover preview
  const [ thumb, setThumb ] = useState( null );  // low res memory for thumb generation
  const [ memory, setMemory ] = useState( null );  // regular res memory for app display
  const [ memoryPreview, setMemoryPreview ] = useState( '' );

  useEffect(() => {
    // cleanup states when opening view again.
    if ( show == true ) {
      return () => {
        setNotify({ type:'ok' });
        setCurrentTitle( '' );
        setCurrentDescription( '' );
        setCover( null );
        setThumb( null );
        setMemory( null );
        setMemoryPreview( '' );
      }
    }
  },[ show ]);




  return(
    <div className={ show ? "cont-fill cc-fadein above" : firstRun ? "cont-fill hidden above" : "cont-fill cc-fadeout above" } >
      <div className={ show ? "card-create cc-fadein above" : firstRun ?  "card-create hidden above" : "card-create cc-fadeout above" } >
        <div className="bg-overlay-caps-dark"></div>

        <div className="cc-title-container">
          {
            type === 'memoria' && <>
            { context.dataType && context.dataType === 'img' && <div className="cc-title">{ title } Visiva</div> }
            { context.dataType && context.dataType === 'vid' && <div className="cc-title">{ title } Video</div> }
            { context.dataType && context.dataType === 'aud' && <div className="cc-title">{ title } Audio</div> }
            { context.dataType && context.dataType === 'txt' && <div className="cc-title">{ title } Testuale</div> }
          </>
          }
          {
            type !== 'memoria' && <>
            <div className="cc-title">{ title }</div>
          </>
          }
          
          <div className="tc-icon" onClick={ () => _action() }>
            <img src={ icon_close } alt="X" />
          </div>
        </div>

        < State_Notify notify={ notify } />

        <div className="cc-ok">
          <p>I ricordi sono preziosi... <br/>sceglili con cura perchè non potrai cancellarli per 3 mesi dal loro inserimento</p>
          <div className="cc-body-container">

            <label htmlFor="title">Titolo</label>
            <input
              name="title"
              type="text"
              placeholder={ "titolo " + type }
              onChange={ (e) => setCurrentTitle( e.target.value ) }
              value={ currentTitle }
              />

            { type === 'memoria' &&
            <>
              { context.dataType && context.dataType === 'img' &&
              <>
                <label htmlFor="cover">Copertina</label>
                <input
                  className="cc-cover rounded-min"
                  name="cover"
                  style={{
                    backgroundColor: 'rgba(59,47,157,0.5)',
                    backgroundImage: `url(${cover})`
                  }}
                  type="file"
                  onChange={ (e) => handleProcess( e.target.files ) }
                  />
              </>
              }

              { context.dataType && context.dataType === 'vid' &&
                <>
                  <label htmlFor="cover">Copertina</label>
                  <input
                    className="cc-cover rounded-min"
                    name="cover"
                    style={{
                      backgroundColor: 'rgba(59,47,157,0.5)',
                      backgroundImage: `url(${cover})`
                    }}
                    type="file"
                    onChange={ ( e ) => processCover( e.target.files, true ) }
                    />

                  <label htmlFor="cover">Video</label>
                  <input
                    className="cc-cover-media rounded-min"
                    name="video"
                    style={{ backgroundColor: 'rgba(59,47,157,0.5)' }}
                    type="file"
                    onChange={ ( e ) => handleProcess( e.target.files ) }
                    />
                </>
              }

              { context.dataType && context.dataType === 'aud' &&
                <>
                  <label htmlFor="cover">Audio</label>
                  <input
                    className="cc-cover-media rounded-min"
                    name="cover"
                    style={{ backgroundColor: 'rgba(59,47,157,0.5)' }}
                    type="file"
                    onChange={ ( e ) => handleProcess( e.target.files ) }
                    />
                  { memoryPreview && <audio controls>
                    <source src={ memoryPreview } />
                    audio non supportato. scarica Firefox!
                  </audio>
                  }
                </>
              }

              { context.dataType && context.dataType === 'txt' &&
                <>
                  <label htmlFor="cover">Testo</label>
                  <textarea
                    className="cc-cover-media rounded-min"
                    name="text"
                    id="2"
                    rows="8"
                    placeholder="Digita qui il tuo ricordo testuale"
                    style={{ backgroundColor: 'rgba(59,47,157,0.5)' }}
                    onChange={ ( e ) => handleProcess( e.target.value ) }
                    ></textarea>
                </>
              }
            </>
            }

            { type !== 'memoria' &&
            <>
              <label htmlFor="cover">Copertina</label>
              <input
                className="cc-cover rounded-min"
                name="cover"
                style={{
                  backgroundColor: 'rgba(59,47,157,0.5)',
                  backgroundImage: `url(${cover})`
                }}
                type="file"
                onChange={ (e) => processCover( e.target.files) }
                />
            </>
            }
            

            { ( context.dataType || type ) !== 'memoria' && context.dataType !== 'txt' &&
              <>
                <label htmlFor="desc">Descrizione</label>
                <textarea
                  name="desc"
                  id="1"
                  rows="10"
                  placeholder={ "Breve descrizione per il tuo " + type }
                  onChange={ ( e ) => setCurrentDescription( e.target.value ) }
                  value={ currentDescription }
                  ></textarea>
              </>
            }


          </div>
        </div>

        <div className="cc-foot">

          { type === "diario" &&
            <button
              onClick={ () => handleCreate() }
              className="btn-tc-big btn-tc-cc-confirm"> CREA DIARIO
            </button>
          }

          { type === "memoria" &&
            <button
              onClick={ () => handleCreate() }
              className="btn-tc-big btn-tc-cc-confirm"> CREA MEMORIA
            </button>
          }

        </div>
      </div>
    </div>
  );




  function handleCreate ()
  {
    // handle creation
    switch ( type ) {
      case 'diario':
        _create({ title:currentTitle, description:currentDescription, thumb:thumb, tokens:context }).then( re => {
          handleWarning( re, 'crea diario' );
        });
        break;

      case 'memoria':
        switch ( context.dataType ) {
          case 'img':
            _create({ title:currentTitle, description:currentDescription, thumb:thumb, memory:memory, context:context }).then( re => {
              handleWarning( re, 'crea memoria immagine' );
            });
            break;
            
          case 'vid':
            _create({ title:currentTitle, description:currentDescription, thumb:thumb, memory:memory, context:context }).then( re => {
              handleWarning( re, 'crea memoria video' );
            });
            break;
            
          case 'aud':
            _create({ title:currentTitle, description:currentDescription, memory:memory, context:context }).then( re => {
              handleWarning( re, 'crea memoria audio' );
            });
            break;
            
          case 'txt':
            _create({ title:currentTitle, memory:memory, context:context }).then( re => {
              handleWarning( re, 'crea memoria testuale' );
            });
            break;
        }
        break;
    }
  }

  function handleProcess ( files )
  {
    switch ( context.dataType ) {
      case 'img':
        processCover( files );
        break;
      case 'vid':
        processVideo( files );
        break;
      case 'aud':
        processAudio( files );
        break;
      case 'txt':
        processText( files );
        break;
    }
  }

  function handleWarning ( response, scope )
  {
    if ( response ) {
      switch ( response[0] ) {

        case 0:
          warn( response[1] );
          break;

        case 2:
          warn( response[1], response[2] );
          break;
      
      }
    } else {
      warn( `no response from ${ scope } operation` );
    }
  }

  // returns a compressed verion of an memory
  function processCover ( images, justThumb = false )
  {
    // set cover preview with plain file.
    setCover( URL.createObjectURL( images[0] ) );

    // process thumb image and set thumb image
    Image_process( images[0], true ).then( thumb => {
      setThumb( thumb.bytes );

      // if not just thumb process and set memory with full size image
      if ( !justThumb ) {
        Image_process( images[0], false ).then( regular => {
          setMemory( regular.bytes );

        }).catch( err => {
          warn( 'cant process full size image', err );
          return false;
        });
      }
      return true;

    }).catch( err => {
      warn( 'cant process thumb image', err );
      return false;
    });
  }

  function processAudio ( files )
  {
    console.log( 'processing audio file...' );

    File_Process( files[0], 'aud' ).then(( res ) => {

      let url = URL.createObjectURL( to_blob( res )[0] );
      setMemoryPreview( url );
      setMemory( res );
      return true;

    }).catch( err => {
      warn( 'cant process audio', err );
      return false;
    });
  }

  function processVideo ( files )
  {
    console.log( 'processing audio file...' );

    File_Process( files[0], 'vid' ).then(( res ) => {

      let url = URL.createObjectURL( to_blob( res )[0] );
      setMemoryPreview( url );
      setMemory( res );
      return true;

    }).catch( err => {
      warn( 'cant process video', err );
      return false;
    });
  }

  function processText ( text )
  {
    setMemory( text );
    return true;
  }

  function warn ( msg, err = null, success = false )
  {
    if ( success ) {
      setNotify({ type:'ok' });
      
      return;
    }

    setNotify({ type:'error', msg:msg, err:err });
    return;
  }

}

export default Create_view;
