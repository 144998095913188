import { useEffect, useState } from 'react';
import qrico from '../img/qr_icon.png';
import friendlyToken from './Utils';
import { Scanner } from '@yudiel/react-qr-scanner';




function QrScan_comp({ type, _scanned }) {

  const [ friendToken, setFriendToken ] = useState("xxxx-xxxx-xxxx-xxxx");
  const [ message, setMessage ] = useState('scansionami');
  const [ token, setToken ] = useState( "" );
  const [ canScan, setCanScan ] = useState( false );




  useEffect(() => {
    switch ( type ) {
      case 'splash':
      case 'assoc' :
        setMessage( 'Scansiona chiave nera' );
        break;
      case 'activate':
        setMessage( 'Scansiona chiave rossa' );
        break;
    }
  }, [ ]);




  return (

    <div className="splash-view-container">
      { !canScan ?
        <img className="qr-icon" onClick={ () => switchScanner() } src={ qrico } alt="qricon" />
        :
        <div className='qr-container'>
          < Scanner
            styles= {{
              container: {
                width: "100%",
                height: "100%",
                minHeight: "250px",
                borderRadius:"8px",
                backgroundColor: "black"
              }
            }}
            components = {{
              finder: false
            }}
            scanDelay= { 500 }
            onScan= { ( re ) => handleScan( re ) }
            />
        </div>
      }


      { type === "splash" &&
        <>
          <h5 className="text-light my-4">{ message }</h5>
          <p className="text-light my-4">
            Scansiona il qr code nero. oppure usa il lettore NFC
            del tuo cellulare per accedere a <b>TIME CAPSULE</b>
          </p>
        </>

      }

      { type === "assoc" &&
        <>
          <h5 className="text-light my-4">{ message }</h5>
          <h5 className="text-warning my-4">{ friendToken }</h5>

          <div className="text-light my-4">

            <p>Scansiona il qr code nero.
            per associare la tua <b>TIME CAPSULE</b></p>
            <p> oppure digita il codice qui sotto </p>
          </div>

          <input 
            className   = "tc-form-field tc-form-input" 
            maxLength   = "16"
            name        = "token"
            type        = "text"
            placeholder = "digita codice"
            value       = { token }
            onChange    = { ( e ) => formatToken( e.target.value ) }
            onBlur      = { ( e ) => {} }
            />

          { alert.state && <div className={ "text-light badge-"+alert.state }> { alert.msg } </div>}

        </>
      }

      { type === "activate" &&
        <>
          <h5 className="text-light my-4">{ message }</h5>
          <h5 className="text-warning my-4">{ friendToken }</h5>

          <h5 className="text-light my-4">
            per attivare il tuo nuovo
            <br /><b>TIME CAPSULE</b>
            <br /> Scansiona il qr code rosso.
            <p><br/> oppure digita il codice qui sotto </p>
          </h5>
          <input 
            className   = "tc-form-field tc-form-input" 
            maxLength   = "16"
            name        = "token"
            type        = "text"
            placeholder = "digita codice"
            value       = { token }
            onChange    = { ( e ) => formatToken( e.target.value ) }
            onBlur      = { ( e ) => {} }
            />
        </>
      }

    </div>
    );

  function handleScan ( re )
  {
    // check type
    if ( typeof re === 'object' ) {
      if ( re.length === 1 ) {
        setMessage( 'timecapsue trovata' );
        let qr_data = re[0].rawValue;
        if ( qr_data.length > 16 ) {
          // if qr has full link, just get the last 16 digits
          // witch compose the token.
          let cutToken = qr_data.substring( qr_data.length - 16 );
          formatToken( cutToken );
        } else {
          // token is clean, just set it
          formatToken( qr_data[0] );
        }

        sendUpdate();
        switchScanner();
      } else {
        setMessage( 'Stai scansionando troppi QR code !' );
      }
    } else {
      console.log( re );
    }
  }

  function sendUpdate ()
  {
    if ( token ) {
      _scanned( token );
    }
  }

  function formatToken ( value )
  {
    // all to CAPS
    let v = value.toUpperCase();
    // update token value
    setToken( v );
    // update showed token
    setFriendToken( friendlyToken( v ) );
    // send message to parent function
    _scanned( v );
  }

  function switchScanner ()
  {
    canScan ? setCanScan( false ) : setCanScan( true );
  }

}

export default QrScan_comp;
