import { useEffect, useState } from "react";
import { Link }       from "react-router-dom";
import { config }     from "../../src";

// functional
import { TC_precheck, TC_switching_fetch } from "../../actions/TC_fetch";
import { to_blob }    from "../../actions/File_Process";
import Spoiler_share  from "../spoilers/Spoiler_share";
import Spoiler_edit   from "../spoilers/Spoiler_edit";
import Spoiler_delete from "../spoilers/Spoiler_delete";
import State_Notify   from "../props/State_Notify";
import Date_since     from "../props/Date_since";

// images and icons
import icon_share     from "../../img/icon_share.png";
import icon_edit      from "../../img/icon_edit.png";
import icon_edit_dark from "../../img/icon_edit_dark.png";
import icon_next      from "../../img/icon_next.png";



function Card_memory ({ tokens, _edit, _delete, update }) {

  const [ date, setDate ] = useState( '' );
  const [ dataType, setDataType ] = useState( null );
  const [ title, setTitle ] = useState( 'caricamento...' );
  const [ description, setDescription ] = useState( 'caricamento...');

  // memory previews
  const [ rawThumb, setRawThumb ] = useState( null );
  const [ thumb, setThumb ] = useState( null );
  const [ audioMemory, setAudioMemory ] = useState( null );
  const [ textMemory, setTextMemory ] = useState( null );

  // UI feedback states ..............................................
  const [ notify, setNotify ] = useState( null );

  const [ confirm, setConfirm ] = useState( null );

  const [ editState, setEditState ] = useState( false );
  const [ shareState, setShareState ] = useState( false );




  useEffect(() => {
    TC_precheck(
      { op:"memory_precheck", memToken:tokens.mem }
    ).then( memory => {

      setDate( memory.memory.created );
      setDataType( memory.memory.data_type );

      TC_switching_fetch(
        { op:"memory_get_data", tcToken:tokens.tc, dyToken:tokens.dy, memToken:tokens.mem, type:memory.memory.data_type },
        `memData_${tokens.mem}`,
        tokens.tc,
        config.store_basedata

      ).then( data => {

        setTitle( data.title );

        // for text memory, description can be inexistent
        if ( data.description != null ) {
          setDescription( data.description );
        }

        getMemoryData( memory.memory.data_type );
        
      }).catch( err => {
        setNotify({ type:'error', msg:err.msg, err:err.err });
      });
    }).catch( err => {
      setNotify({ type:'error', msg:err.msg, err:err.err });
    })

  }, [ update ] );




  return (
    <div className="tc-card-memory-main">
      <div className="tc-card-header">
        <div className="card-title">{ title }</div>
        <Date_since date={ date }/>
      </div>


      { dataType === "img" &&
      <div className="tc-card">
        <div className="tc-card-test bg-main">

          <Link to={ `/memory/${tokens.tc}:${tokens.dy}:${tokens.mem}`}>
            <div className="tc-icon tc-icon-main">
              <img src={ icon_next } alt="entra" />
            </div>
          </Link>

          <div className="tc-icon tc-icon-secondary" onClick={ () => switchShare() } >
            <img src={ icon_share } alt="condividi" />
          </div>

          <div className="tc-icon tc-icon-edit" onClick={ () => switchEdit() }>
            <img src={ icon_edit } alt="modifica" />
          </div>

          <div className="thumb-card-cover thumb-borderline" style={{ backgroundImage:`url(${thumb})` }} ></div>

          <div className="thumb-card-description text-light p-2">
            { description &&
              <p>{ description }</p>
            }
          </div>

        </div>

        <div className={ shareState ? "tc-card-share h-slide-in" : "tc-card-share" }>
        < Spoiler_share
          fu1 = { () => console.log('share function 1') }
          fu2 = { () => console.log('share function 2') }
          fu3 = { () => console.log('share function 3') }
          />
        </div>

        <div className={ editState ? "tc-card-share h-slide-in" : "tc-card-share" }>
          < Spoiler_edit
            fu1 = { () => handleEdit() }
            fu2 = { () => switchConfirm() }
            />
        </div>

        { confirm && 
          < Spoiler_delete
            scope   = { 'memoria' }
            _keep   = { () => switchConfirm() }
            _delete = { () => handleDelete() }
            />
        }
      </div>
      }

      { dataType === "vid" &&
      <div className="tc-card">
        <div className="tc-card-test bg-main">

          <Link to={ `/memory/${tokens.tc}:${tokens.dy}:${tokens.mem}`}>
            <div className="tc-icon tc-icon-main">
              <img src={ icon_next } alt="entra" />
            </div>
          </Link>

          <div className="tc-icon tc-icon-secondary" onClick={ () => switchShare() } >
            <img src={ icon_share } alt="condividi" />
          </div>

          <div className="tc-icon tc-icon-edit" onClick={ () => switchEdit() }>
            <img src={ icon_edit } alt="modifica" />
          </div>

          <div className="thumb-card-cover thumb-borderline" style={{ backgroundImage:`url(${thumb})` }} ></div>

          <div className="thumb-card-description text-light p-2">
            { description &&
              <p>{ description }</p>
            }
          </div>
        </div>

        <div className={ shareState ? "tc-card-share h-slide-in" : "tc-card-share" }>
        < Spoiler_share
          fu1 = { () => console.log('share function 1') }
          fu2 = { () => console.log('share function 2') }
          fu3 = { () => console.log('share function 3') }
          />
        </div>

        <div className={ editState ? "tc-card-share h-slide-in" : "tc-card-share" }>
          < Spoiler_edit
            fu1 = { () => handleEdit() }
            fu2 = { () => switchConfirm() }
            />
        </div>

        { confirm && 
          < Spoiler_delete
            scope   = { 'memoria' }
            _keep   = { () => switchConfirm() }
            _delete = { () => handleDelete() }
            />
        }
      </div>
      }

      { dataType === "aud" && audioMemory &&
      <div className="tc-card small">
        <div className="tc-card-short bg-main">

          <div className="tc-icon tc-icon-edit-audio" onClick={ () => switchEdit() }>
            <img src={ icon_edit } alt="modifica" />
          </div>

          <div className="audio-player-container">
            <div className="audio-description text-light soft-shadow p-2">
              { description &&
                <p>{ description }</p>
              }
            </div>

            <audio controls className="audio-player">
              <source src={ audioMemory } />
              audio non supportato. scarica Firefox!
            </audio>
          </div>

        </div>

        <div className={ editState ? "tc-card-share h-slide-in" : "tc-card-share" }>
          < Spoiler_edit
            fu1 = { () => handleEdit() }
            fu2 = { () => switchConfirm() }
            />
        </div>

        { confirm && 
          < Spoiler_delete
            scope   = { 'memoria' }
            _keep   = { () => switchConfirm() }
            _delete = { () => handleDelete() }
            />
        }
      </div>
      }

      { dataType === "txt" &&
      <div className="tc-card-text">
        <div className="memory-text-container">

          <div className="tc-icon memory-text-edit-icon" onClick={ () => switchEdit() }>
            <img src={ icon_edit_dark} alt="modifica" />
          </div>

          { textMemory && textMemory ? <p className="Font-dancing">{ textMemory }</p> : <p>non trovo la memoria</p> }
        </div>

      <div className={ shareState ? "tc-card-share h-slide-in" : "tc-card-share" }>
        < Spoiler_share
          fu1 = { () => console.log('share function 1') }
          fu2 = { () => console.log('share function 2') }
          fu3 = { () => console.log('share function 3') }
          />
      </div>

      <div className={ editState ? "tc-card-share h-slide-in" : "tc-card-share" }>
        < Spoiler_edit
          fu1 = { () => handleEdit() }
          fu2 = { () => switchConfirm() }
          />
      </div>

      { confirm && 
        < Spoiler_delete
          scope   = { 'memoria' }
          _keep   = { () => switchConfirm() }
          _delete = { () => handleDelete() }
          />
      }
      </div>
      }
      
      < State_Notify notify={ notify } />
    </div>
  );




  function get_thumb () {
    return new Promise(( resolve, reject ) => {
      TC_switching_fetch(
        { op:"memory_get_thumb", tcToken:tokens.tc, dyToken:tokens.dy, memToken:tokens.mem },
        `memThumb_${tokens.mem}`,
        tokens.tc,
        config.store_thumb

      ).then( data => {
        resolve( data );
      }).catch( err => {
        reject( err );
      });
    });
  }

  function get_memory () {
    return new Promise(( resolve, reject ) => {
      TC_switching_fetch(
        { op:"memory_get_content", tcToken:tokens.tc, dyToken:tokens.dy, memToken:tokens.mem },
        `memory_${tokens.mem}`,
        tokens.tc,
        config.store_content

      ).then( data => {
        resolve( data );
      }).catch( err => {
        reject( err );
      });
    });
  }

  function getMemoryData ( type ) {
    switch ( type ) {

      case 'img':
      case 'vid':
        get_thumb().then( data => {
          updateThumb( data.thumb );
        }).catch( err => {
          setNotify({ type:'error', msg:err.msg, err:err.err });
        });
      break;

      case 'aud':
      case 'txt':
        get_memory().then( data => {
          if ( type === 'aud' ) {
            processAudio( data.memory );
          } else {
            setTextMemory( data.memory );
          }
        }).catch( err => {
          setNotify({ type:'error', msg:err.msg, err:err.err });
        });
      break;
    }
  }

  function handleDelete ()
  {
    _delete({ tokens:tokens }).then( re => {
      switchConfirm();
    }).catch( err => {
      handleWarning( err, 'delete' );
    });
  }

  function handleEdit ()
  {
    _edit({ title:title, description:description, tokens:tokens, subtype:dataType });
  }

  function handleWarning ( response, scope )
  {
    if ( response ) {
      switch ( response[0] ) {

        case 0:
          setNotify({ type:'error', msg:response[1] });
          break;

        case 2:
          setNotify({ type:'error', msg:response[1], err:response[2] });
          break;
      
      }
    } else {
      setNotify({ type:'error', msg:`no response from ${ scope } operation` });
    }
  }

  function updateThumb ( dataString )
  {
    let memory_data = to_blob( dataString );
    // update image, justincase
    let img = new File( [ memory_data[0] ], 'thumb'+tokens.dy, { type:memory_data[1] });
    // generate url for ui integration
    var imageUrl = URL.createObjectURL(img);
    // update image state. boom.
    setRawThumb( dataString );
    setThumb( imageUrl );
  }

  function processAudio ( dataString )
  {
    let url = URL.createObjectURL( to_blob( dataString )[0] );
    setAudioMemory( url );
    return true;
  }

  function switchShare ()
  {
    shareState ? setShareState( false ) : setShareState( true );
    if ( editState === true ) {
      setEditState( false );
    }
  }

  function switchEdit ()
  {
    editState ? setEditState( false ) : setEditState( true );
    if ( shareState === true ) {
      setShareState( false );
    }
  }

  function switchConfirm ()
  {
    confirm ? setConfirm( false ) : setConfirm( true );
  }

}

export default Card_memory;
