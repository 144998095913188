import "./Bubble_Message.css";

export default function Bubble_Message ({ style, message }) {
  switch ( style ) {

    case 'solo': // placeholder
      return(
        <>
          <div className="solo-msg-bubble">
            { message }
          </div>
        </>
      );
    
    case 'full':
      return(
        <>
          <div className="full-msg-bubble">
            { message }
          </div>
        </>
      );

    case 'faded': // placeholder
      return(
        <>
          <div className="faded-msg-bubble">
            { message }
          </div>
        </>
      );
      
    default:
      return(
        <>
          <div className="card-msg-bubble">
            { message }
          </div>
        </>
      );
  }
}