import { useState } from "react";

// functional
import Spoiler_settings from "../components/spoilers/Spoiler_settings";
import Dash_nav_comp    from "../components/dash/Dash_nav_comp";

function Privacy_view () {

  const [ settingsViewActive, setSettingsViewActive ] = useState( false );


  return (
    <div className="main-view-container view-main">

      < Spoiler_settings 
        show = { settingsViewActive }
        _toggle = { () => switchSettingsView() }
        />

      <div className="simple-container p-4">

        <h3>Privacy Policy</h3>

        <p>Titolare del trattamento Tassinari Francesca  di seguito indicato come Dream it,
          effettua il trattamento dei dati personali nel pieno rispetto del REGOLAMENTO (UE) 2016/679. 
          In conformità a tale normativa, il trattamento dei dati sarà improntato ai principi di correttezza, 
          liceità e trasparenza e di tutela della riservatezza e dei diritti del Cliente.
          I dati sono inseriti nelle banche dati di Dream it, in seguito all'acquisizione del consenso 
          della persona interessata.</p>

        <p>La tipologia di dati che raccogliamo:</p>

        <h3>Recapiti</h3>
        <p>Il tuo nome e cognome, l’indirizzo di spedizione e di fatturazione, numero di telefono, indirizzo e-mail.</p>

        <h3>Dati identificativi</h3>

        <p>Il tuo indirizzo IP, informazioni di login, tipo e versione del browser, fuso orario, 
        tipologie di plug-in, informazioni di geolocalizzazione riguardo il luogo dove potresti trovarti, 
        sistema operativo e relative versioni, ecc..</p>

        <h3>Dati relativi l’utilizzo del nostro </h3>
        
        <p>Il tuo percorso di navigazione all’interno del sito, 
        le tue visualizzazioni di prodotti e servizi, 
        tempi di caricamento e risposta delle pagine, 
        errori di download, durata della navigazione, 
        comportamento e altre azioni, ecc..</p>

        <p>Non raccogliamo nessun tipo di dato sensibile che possa riguardarti 
        (cioè quei dati personali che rivelano l’origine razziale o etnica, 
        le opinioni politiche, le convinzioni religiose o filosofiche, 
        o l’appartenenza sindacale, dati genetici, dati biometrici, 
        dati relativi alla salute o alla vita sessuale o all’orientamento sessuale) .</p>

        <h3>Utilizzo del sito e gestione dei servizi:</h3>

        <p>I dati sono trattati per consentire la registrazione al Sito, 
        l’accesso e l’esecuzione dei servizi riservati agli utenti registrati e 
        l’eventuale invio attraverso il Sito di un ordine di acquisto dei prodotti 
        disponibili sul Sito con la conseguente conclusione con Dream it del 
        relativo contratto di compravendita, comprese le eventuali formalità 
        amministrative e contabili. In questo caso la base giuridica è 
        costituita dal contratto e dal legittimo interesse.</p>

        <h3>Supporto clienti</h3>

        <p>Comunicazione delle modifiche dei nostri servizi, 
        assistenza clienti via telefono o e-mail, inclusa la correzione di eventuali bug. 
        In questo caso la base giuridica è costituita dal contratto.</p>

        <h3>Marketing</h3>

        <p>Potremmo utilizzare i tuoi dati per individuare le informazioni 
        per te potenzialmente rilevanti (per esempio, inviarti e-mail 
        personalizzate basate sulle tue preferenze da noi rilevate). 
        I dati sono trattati per l’invio di materiale pubblicitario e di 
        newsletter, per svolgere attività di informazione commerciale, 
        tramite telefono, SMS, posta elettronica, per il compimento di 
        ricerche di mercato e di indagini per la rilevazione della soddisfazione 
        dei clienti. In questo caso la base giuridica è costituita dal tuo consenso. 
        Altrimenti, utilizzeremo i tuoi dati solo per fornirti i nostri servizi.</p>

        <h3>Profilazione</h3>
        <p>Al solo fine di proporti offerte per quanto possibile in linea con i tuoi 
        interessi eseguiremo alcune analisi su dati aggregati senza svolgere alcuna 
        attività di profilazione sulle tue specifiche abitudini. In questo caso la base 
        giuridica è costituita dall’interesse legittimo.
        In ogni caso non esiste alcun processo decisionale automatizzato che ti riguarda.
        I dati personali raccolti con l’inoltro dell’Ordine verranno trattati da Dream 
        it al solo fine di soddisfare le espresse richieste del Cliente. Eventuali ulteriori 
        trattamenti verranno effettuati unicamente con l’espresso consenso del Cliente.</p>

        <h3>Modalità di trattamento:</h3>
        <p>Il trattamento dei dati avviene con modalità prevalentemente elettroniche, 
        ad esclusione delle attività di spedizione dei prodotti che possono essere 
        eseguite anche con strumenti manuali.
        I dati personali sono raccolti a fini contrattuali, amministrativo-contabili, 
        commerciali, statistici e per l’instaurazione di normali contatti commerciali. 
        I dati acquisiti potranno essere comunicati a spedizionieri, consulenti per 
        l’attuazione dei fini sopracitati, nonché per iniziative di marketing, pubblicitarie, 
        promozionali. In Dream It i dati personali potranno essere comunicati al 
        personale incaricati del trattamento.</p>

        <h3>Dove vengono conservati i tuoi dati?</h3>
        <p>I tuoi dati vengono trattati all’interno dell’Unione Europea e nelle 
        altre strutture dati gestite dai terzi di seguito identificati.
        Dal momento in cui ci comunichi i tuoi dati personali, acconsenti anche 
        al loro trasferimento, alla loro memorizzazione e trattamento da parte nostra.
        Nel caso avessimo la necessità di trasferire e/o memorizzare i tuoi dati 
        al di fuori dell’UE, sarai adeguatamente informato in merito. Anche in tale ipotesi, 
        adotteremo tutte le misure idonee a garantirne la massima protezione.</p>

        <h3>Per quanto tempo conserveremo i tuoi dati?</h3>
        <p>Cesseremo il trattamento attivo dei tuoi dati entro dodici mesi dall’ultimo 
        utilizzo del servizio oppure dalla chiusura del tuo account, salvo eventuali 
        obblighi di legge (es. fatture di vendita) non ci impongano di conservare i 
        tuoi dati per un tempo superiore. Per le attività promozionali a Te rivolte, 
        che potrai sempre chiederci di interrompere, conserveremo i tuoi dati per un 
        tempo massimo di ventiquattro mesi dalla tua iscrizione al servizio.</p>

        <h3>Terze parti che possono trattare i tuoi dati:</h3>

        <p>Per svolgere la nostra attività online abbiamo la necessità di avvalerci di 
        terze parti per ospitare le applicazioni, comunicare con i clienti, gestire 
        le e-mail, ecc. Ti assicuriamo che questa collaborazione avviene con i migliori 
        soggetti presenti sul mercato che prestano questo tipo servizi. Condividiamo i 
        tuoi dati con loro solo quando è necessario per assicurare la massima funzionalità 
        dei nostri servizi e in conformità con le misure di sicurezza e le buone pratiche 
        descritte nella presente Informativa sulla Privacy.</p>

        <h3>Diritti degli interessati</h3>

        <p>I soggetti ai quali si riferiscono i dati personali possono in qualunque momento 
        accedere alle informazioni che li riguardano e ottenere:
        – conferma dell’esistenza o meno dei dati stessi
        – conoscenza del contenuto e dell’origine
        – verifica dell’esattezza
        – integrazione o aggiornamento
        – rettifica
        – richiedere la cancellazione dei dati (diritto all’oblio)
        – richiedere la trasformazione in forma anonima
        richiedere il blocco dei dati trattati in violazione di legge
        opporsi in ogni caso, per motivi legittimi, al loro trattamento
        Inoltre, se richiesto possiamo fornire loro:
        le categorie di dati che trattiamo;
        le finalità del trattamento;
        le categorie di eventuali destinatari a cui i dati potrebbero essere comunicati;
        il periodo di conservazione dei dati o, in mancanza, i criteri per determinare 
        tale periodo;
        gli altri diritti riguardanti l’utilizzo dei tuoi dati.
        Ti forniremo le informazioni entro un mese dalla tua richiesta, salvo che ciò non 
        pregiudichi i diritti e le libertà di altri soggetti (ad esempio la riservatezza di un’altra 
        persona o i diritti di proprietà intellettuale) oppure qualora vi siano obblighi di legge che 
        glielo impediscano. Ti informeremo nel caso in cui non riuscissimo a soddisfare la tua 
        richiesta per tali ragioni.
        Puoi sempre rivolgerti all’Autorità di controllo per proporre i tuoi eventuali reclami. 
        Prima di fare ciò, contattaci pure, saremo lieti di risolvere tutti gli eventuali problemi 
        relativi al trattamento dei tuoi dati personali.
        Hai il diritto di opporti all’utilizzo dei tuoi dati mediante decisioni automatizzate, 
        compresa la profilazione.
        Garantiamo i tuoi diritti, anche se trattiamo i tuoi dati personali per conto di terzi.
        Se trattiamo i tuoi dati per conto di terzi, ti garantiamo che le richieste relative 
        all’esercizio dei tuoi diritti saranno loro trasmesse senza ingiustificato ritardo.</p>

        <h4>Per qualsiasi informazione ti invitiamo a contattarci, siamo sempre a disposizione 
        della nostra clientela al numero +39 338 1927702 
        oppure via mail a info@dreamit.shop</h4>

      </div>

      <div className="cont-bottom">

        <Dash_nav_comp
          backLink="/dash" 
          type = "home"
          _action = { null }
          _settings = { () => switchSettingsView() }
          />

      </div>
    </div>

  );

  function topScroll () {
    document.getElementById('top').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  function switchSettingsView ( )
  {
    settingsViewActive ? setSettingsViewActive( false ) : setSettingsViewActive( true );
  }

}

export default Privacy_view;