import * as CryptoJS from 'crypto-js';

async function TC_decrypt ( dataString, secret ) {
  if ( dataString != null && secret != null ) {
    const bytes = CryptoJS.AES.decrypt( dataString, secret );
    var plainText = null;

    try {
      plainText = bytes.toString( CryptoJS.enc.Utf8 );
    } catch (error) {
      console.log(error);
      return false;
    }

    return plainText;
  } else {
    return false;
  }

}

export default TC_decrypt;
